import React from 'react'
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material'
import Text from '../../../common/Text'
import { ErrorMessage, LoadingSpinner } from 'src/components/common'
import NotesRow from './NotesRow'
import { useNotesItems } from 'src/hooks/ManageWorkOrder'
import { useTranslation } from 'react-i18next'
import type { NotesItems } from 'src/types/manageWorkOrder'

type NotesTableProps = {
  workOrderId: number
}

type NotesItemResult = {
  data: NotesItems[]
  isLoading: boolean
  error: Error
}

export type CellProps = {
  title: string | number
  icon?: React.ReactNode
  width?: string
}

const HeaderCell = ({ title }: CellProps) => {
  const formattedTitle =
    typeof title === 'string'
      ? title.toUpperCase()
      : String(title).toUpperCase()
  return (
    <TableCell sx={{ padding: '5px 0', margin: 0 }}>
      <Text
        align='left'
        fontStyle='normal normal 600 14px/14px Poppins;'
        opacity={0.5}
        upperCase={true}
      >
        {formattedTitle}
      </Text>
    </TableCell>
  )
}

function NotesTable({ workOrderId }: NotesTableProps) {
  const { t } = useTranslation()
  const columns = [t('date'), t('user'), t('note')]
  const {
    data: NotesItems,
    isLoading,
    error,
  } = useNotesItems(workOrderId) as NotesItemResult
  const filteredNotesItems =
    NotesItems?.filter((item) => item?.Active === 1) ?? []
  return (
    <TableContainer
      sx={{
        width: '750px',
        padding: '13px 13px 0 13px',
        overflowY: 'auto',
        maxHeight: '430px',
      }}
    >
      <Table>
        <TableHead>
          <TableRow>
            {columns.map((column, index) => (
              <HeaderCell
                key={index}
                title={column}
              />
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {isLoading ? (
            <LoadingSpinner
              height='37vh'
              width='20vh'
            />
          ) : error ? (
            <ErrorMessage message={` ${error}`} />
          ) : (
            filteredNotesItems?.map((row) => (
              <NotesRow
                key={row.NoteId}
                row={row}
              />
            ))
          )}
        </TableBody>
      </Table>
    </TableContainer>
  )
}

export default NotesTable
