import React from 'react'
import { Grid } from '@mui/material'
import { Text } from 'src/components/common'

const colorByStatus: Record<string, string> = {
  flagged: '#C40B0B',
  inProduction: '#AED432',
  inQueue: 'rgba(255,255,255,0.25)',
} as const

const renderTaskItem = (
  index: number,
  task: number,
  currentTask: number,
  lastCompleteIndex: number,
  pipeLineStatus: string,
  isLiveViewTable?: boolean
) => (
  <Grid
    key={task}
    item
    sx={{
      paddingRight: 2,
      backgroundColor: task <= currentTask ? colorByStatus[pipeLineStatus] : '',
      ...(!isLiveViewTable && index === lastCompleteIndex
        ? {
            borderTopRightRadius: '20px',
            borderBottomRightRadius: '20px',
          }
        : {}),
    }}
    flex={1}
  >
    <Text
      fontStyle='normal normal bold 30px/30px Poppins'
      opacity={task <= currentTask ? 1 : 0.5}
    >
      {task}
    </Text>
  </Grid>
)

const getListToRender = (currentTask: number, totalTask: number) => {
  const list: number[] = []
  if (totalTask <= 3) {
    for (let i = 1; i <= totalTask; i++) {
      list.push(i)
    }
  } else if (currentTask === totalTask) {
    const start = totalTask - 3
    for (let i = start; i <= totalTask; i++) {
      list.push(i)
    }
  } else if (currentTask <= 3) {
    for (let i = 1; i <= 4; i++) {
      list.push(i)
    }
  } else {
    const start = currentTask - 2
    for (let i = start; i < start + 4; i++) {
      list.push(i)
    }
  }

  return list
}

const getCurrentTaskToRender = (currentTask: number, totalTask: number) => {
  const list: number[] = []

  if (currentTask <= 0) {
    list.push(1)
  }

  if (currentTask >= 1 && currentTask <= totalTask) {
    list.push(currentTask)
  }

  return list
}

type PipeLineProps = {
  pipeLineStatus: string
  currentTask: number
  totalTask: number
  isLiveViewTable?: boolean
}

function PipeLine({
  pipeLineStatus,
  currentTask,
  totalTask,
  isLiveViewTable,
}: PipeLineProps) {
  const listToRender = getListToRender(currentTask, totalTask)
  const currentTaskToRender = getCurrentTaskToRender(currentTask, totalTask)
  const lastCompleteIndex = listToRender.indexOf(currentTask)

  return (
    <Grid
      item
      flex={1}
      container
      justifyContent='space-around'
      alignItems='center'
    >
      {isLiveViewTable
        ? currentTaskToRender.map((task, index) =>
            renderTaskItem(
              index,
              task,
              currentTask,
              lastCompleteIndex,
              pipeLineStatus,
              isLiveViewTable
            )
          )
        : listToRender.map((task, index) =>
            renderTaskItem(
              index,
              task,
              currentTask,
              lastCompleteIndex,
              pipeLineStatus
            )
          )}
    </Grid>
  )
}

export default PipeLine
