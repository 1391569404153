import {
  createTheme,
  ThemeProvider as MUIThemeProvider,
} from '@mui/material/styles'

const scrollStyles = {
  '&::-webkit-scrollbar': {
    right: '5px',
    width: '12px',
    borderRadius: '10px',
  },
  '&::-webkit-scrollbar-thumb': {
    backgroundColor: '#FFF',
    height: '100px',
    borderRadius: '10px',
  },
  '::-webkit-scrollbar-track': {
    backgroundColor: 'black',
    borderRadius: '10px',
    marginBottom: '18px',
  },
}

const theme = createTheme({
  components: {
    MuiTableContainer: {
      styleOverrides: {
        root: scrollStyles,
      },
    },
    MuiDialog: {
      styleOverrides: {
        paper: {
          backgroundColor: '#000',
          borderRadius: '23px',
          minWidth: '1000px',
          minHeight: '257px',
        },
      },
    },
    MuiDialogContent: {
      defaultProps: {
        dividers: false,
      },
    },
    MuiCard: {
      styleOverrides: {
        root: {
          backgroundColor: '#2A323C',
          borderRadius: '15px',
        },
      },
    },
    MuiCardContent: {
      styleOverrides: {
        root: scrollStyles,
      },
    },
    MuiCardHeader: {
      styleOverrides: {
        root: {
          color: 'white',
        },
      },
    },
    MuiTextField: {
      styleOverrides: {
        root: {
          '&:hover .MuiOutlinedInput-notchedOutline': {
            borderColor: ' white',
          },

          fieldset: {
            border: '2px solid white',
            borderRadius: 20,
          },
          input: {
            '&[type=number]': {
              '-moz-appearance': 'textfield',
            },
            '&::-webkit-outer-spin-button': {
              '-webkit-appearance': 'none',
              margin: 0,
            },
            '&::-webkit-inner-spin-button': {
              '-webkit-appearance': 'none',
              margin: 0,
            },
          },
        },
      },
      defaultProps: {
        InputProps: {
          sx: {
            color: 'white',
            font: 'normal normal normal 18px/12px Poppins',
          },
        },
      },
    },
  },

  typography: {
    allVariants: {
      color: '#FFF',
    },
  },
  palette: {
    grey: {},
    primary: {
      main: '#aed432',
    },
    error: {
      main: '#FF0000',
    },
  },
})

type ThemeProviderPropsType = {
  children: React.ReactNode
}
export const ThemeProvider = ({ children }: ThemeProviderPropsType) => {
  return <MUIThemeProvider theme={theme}>{children}</MUIThemeProvider>
}
