import { Box, Grid } from '@mui/material'
import { Text } from 'src/components/common'

const LiveViewTableFooter = () => {
  return (
    <Grid item container sx={{ margin: 2 }}>
      <Grid item container xs={2}>
        <Box
          sx={{
            width: 30,
            height: 30,
            backgroundColor: '#FFFFFF',
            opacity: 0.25,
            borderRadius: '7px',
          }}
        />
        <Text
          upperCase={true}
          fontStyle='normal normal 600 22px/14px Poppins'
          color='#FFF'
          opacity={0.5}
          align='left'
        >
          waiting in queue
        </Text>
      </Grid>
      <Grid item container xs={2}>
        <Box
          sx={{
            width: 30,
            height: 30,
            backgroundColor: '#AED432',
            borderRadius: '7px',
          }}
        />
        <Text
          upperCase={true}
          fontStyle='normal normal 600 22px/14px Poppins'
          color='#FFF'
          opacity={0.5}
          align='left'
        >
          in production
        </Text>
      </Grid>
      <Grid item container xs={3}>
        <Box
          sx={{
            width: 30,
            height: 30,
            backgroundColor: '#C40B0B',
            borderRadius: '7px',
          }}
        />
        <Text
          upperCase={true}
          fontStyle='normal normal 600 22px/14px Poppins'
          color='#FFF'
          opacity={0.5}
          align='left'
        >
          flagged
        </Text>
      </Grid>
    </Grid>
  )
}

export default LiveViewTableFooter
