import React from 'react'
import { Box } from '@mui/material'
import { DialogAlert, FilledButton, Text } from 'src/components/common'
import { useTranslation } from 'react-i18next'

type StageCompleteDialogProps = {
  isOpen: boolean
  closeDialog: () => void
  onPrimaryButtonClick: () => void
}

function StageCompleteDialog({
  isOpen,
  closeDialog,
  onPrimaryButtonClick,
}: StageCompleteDialogProps) {
  const { t } = useTranslation()
  return (
    <DialogAlert
      isOpen={isOpen}
      handleClose={closeDialog}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          width: '1000px',
          height: '376px',
        }}
      >
        <Text
          color='#AED432'
          fontStyle='normal normal 600 40px/36px Poppins'
        >
          {t('Stage Completed!')}
        </Text>
        <Text
          color='#727881'
          fontStyle='normal normal normal 27px/36px Poppins'
          typographyProps={{ sx: { padding: 0, marginTop: 2 } }}
        >
          {t('Ready to move this Work Order to the next stage?')}
        </Text>
        <Text
          color='#727881'
          fontStyle='normal normal normal 27px/36px Poppins'
          typographyProps={{ sx: { padding: 0, marginBottom: 2 } }}
        >
          {t('Please confirm below.')}
        </Text>
        <div style={{ display: 'flex', gap: 30 }}>
          <FilledButton
            sx={{
              height: '60px',
              width: '240px',
              backgroundColor: 'rgba(255,255,255,0.25)',
            }}
            textProps={{
              fontStyle: 'normal normal bold 18px/14px Poppins',
              upperCase: true,
              typographyProps: { sx: { padding: 0 } },
            }}
            onClick={closeDialog}
          >
            {t('cancel')}
          </FilledButton>
          <FilledButton
            sx={{ height: '60px', padding: 2 }}
            textProps={{
              fontStyle: 'normal normal bold 18px/14px Poppins',
              upperCase: true,
              typographyProps: { sx: { padding: 0 } },
            }}
            onClick={onPrimaryButtonClick}
          >
            {t('confirm next stage')}
          </FilledButton>
        </div>
      </Box>
    </DialogAlert>
  )
}

export default StageCompleteDialog
