export default function FlagIcon() {
  return (
    <svg
      version='1.2'
      xmlns='http://www.w3.org/2000/svg'
      viewBox='0 0 26 26'
      width='26'
      height='26'
    >
      <path
        d='M3.2,3.8v18.4c0,0.2-0.2,0.5-0.5,0.5H1.9c-0.2,0-0.5-0.2-0.5-0.5V3.8C0.8,3.5,0.5,2.9,0.5,2.2
		c0-1,0.8-1.9,1.9-1.9s1.9,0.8,1.9,1.9C4.2,2.9,3.8,3.5,3.2,3.8z M25.5,14.2c0,0.5-0.3,0.7-0.8,1c-1.6,0.9-3.5,1.7-5.4,1.7
		c-2.7,0-3.9-2-7.1-2c-2.3,0-4.7,1-6.7,2.1c-0.2,0.1-0.3,0.1-0.5,0.1c-0.5,0-0.9-0.4-0.9-0.9V5.4c0-0.3,0.2-0.6,0.4-0.8
		C5,4.3,5.4,4.1,5.8,4c1.8-0.9,4-1.7,6.1-1.7c2.3,0,4.1,0.8,6.1,1.7c0.4,0.2,0.8,0.3,1.3,0.3c2.3,0,4.7-2,5.4-2
		c0.5,0,0.9,0.4,0.9,0.9V14.2z'
      />
    </svg>
  )
}
