import React from 'react'
import { Grid, SvgIcon } from '@mui/material'
import { useNavigate } from 'react-router-dom'
import { FilledButton, OutlinedButton } from 'src/components/common'
import { CardSubContainer, CardSubContent } from '../common/Card'
import { NoteIcon, RawIcon } from '../common/svg'
import { UpdateIcon } from '../common/svg/UpdateIcon'
import { PencilBoxIcon } from '../common/svg/PencilBoxIcon'
import { formatDate } from 'src/utils/formatDate'
import { useTranslation } from 'react-i18next'
import useGoogleTranslation from 'src/services/useGoogleTranslation'
import type { WorkOrderSummaryDetails } from 'src/types/workOrderSummary'

type WorkFlowSummaryDetailsCardProps = {
  orderDetails: WorkOrderSummaryDetails
  opacity: number
}

export default function WorkOrderSummaryDetailsCard({
  orderDetails,
  opacity,
}: WorkFlowSummaryDetailsCardProps) {
  const DecorationType = useGoogleTranslation(orderDetails.DecorationType)
  const PipelineLabel = useGoogleTranslation(orderDetails.PipelineLabel)
  const StageLabel = useGoogleTranslation(orderDetails.StageLabel)
  const navigate = useNavigate()
  const { t } = useTranslation()
  return (
    <CardSubContainer opacity={opacity}>
      <Grid
        container
        padding={2}
      >
        <Grid
          container
          item
          rowGap={3}
          xs={8}
        >
          <CardSubContent
            title={t('job_name')}
            value={orderDetails.JobName ?? '- -'}
          />
          <CardSubContent
            title={t('decoration_type')}
            value={DecorationType ?? '- -'}
            valueUpperCase={true}
          />
          <CardSubContent
            title={t('customer_name')}
            value={orderDetails.CustomerName ?? '- -'}
            width='40%'
          />
          <CardSubContent
            title={t('pipeline')}
            value={PipelineLabel ?? '- -'}
            icon={<RawIcon />}
          />
          <CardSubContent
            title={t('quantity')}
            value={orderDetails.TotalQuantity ?? '- -'}
          />
          <CardSubContent
            title={t('ship_date')}
            value={formatDate(orderDetails.EstimatedShippingDate) ?? '- -'}
          />
          <CardSubContent
            title={t('# of artifacts/colors/stitches')}
            value={orderDetails.NumberOfArtifacts ?? '- -'}
            width='40%'
          />
          <CardSubContent
            title={t('stage')}
            value={StageLabel ?? '- -'}
            icon={<NoteIcon />}
          />
        </Grid>
        <Grid
          container
          item
          xs={4}
          gap={3}
          justifyContent='center'
          alignItems='center'
        >
          <OutlinedButton
            onClick={() =>
              navigate(`/manage-pipeline-tasks`, {
                state: { orderDetails },
              })
            }
            sx={{
              width: '165px',
              height: '60px',
              borderRadius: '8px',
              padding: 0,
              '& .MuiButton-startIcon': { marginRight: 1 },
            }}
            startIcon={
              <SvgIcon
                sx={{ color: '#FFF', height: 30 }}
                viewBox='0 0 26 30 '
              >
                <UpdateIcon />
              </SvgIcon>
            }
            buttonProps={{}}
            textProps={{
              fontStyle: 'normal normal bold 18px/14px Poppins',
              typographyProps: { padding: 0 },
            }}
          >
            {t('Update')}
          </OutlinedButton>
          <FilledButton
            onClick={() =>
              navigate(`/manage-work-order`, { state: { orderDetails } })
            }
            startIcon={
              <SvgIcon
                sx={{ color: '#FFF', width: 30, height: 30 }}
                viewBox='0 -3 46 46'
              >
                <PencilBoxIcon />
              </SvgIcon>
            }
            sx={{
              width: '150px',
              height: '60px',
              borderRadius: '8px',
              '& .MuiButton-startIcon': { marginRight: 1 },
            }}
            textProps={{
              fontStyle: 'normal normal bold 18px/14px Poppins',
              typographyProps: { padding: 0 },
            }}
          >
            {t('view')}
          </FilledButton>
        </Grid>
      </Grid>
    </CardSubContainer>
  )
}
