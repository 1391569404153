import { useMutation, useQueryClient } from 'react-query'
import { fetchWrapper } from 'src/utils/fetchWrapper'
import { toast } from 'react-toastify'
import { useTranslation } from 'react-i18next'

export function useTaskUpdate(workOrderID: number) {
  const API_ENDPOINT = `/workorders/${workOrderID}/pipeline/stage/update`
  const queryClient = useQueryClient()
  const { t } = useTranslation()

  const updateTask = async (data: any) => {
    try {
      const updatedData = await fetchWrapper({
        method: 'PUT',
        url: API_ENDPOINT,
        body: data,
      })
      toast.success(t('Task updated successfully'))
      return updatedData
    } catch (error) {
      console.error('API request failed:', error)
      toast.error(t('Failed to update Task'))
      throw error
    }
  }

  return useMutation('workOrderDetails', updateTask, {
    onSuccess: () => {
      queryClient.invalidateQueries('workOrderDetails')
    },
  })
}
