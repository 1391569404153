import React from 'react'
import {
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material'
import Text from '../../../common/Text'
import {
  ErrorMessage,
  InfoSection,
  LoadingSpinner,
} from 'src/components/common'
import InventoryRow from './InventoryRow'
import { useFromInventoryItems } from 'src/hooks/ManageWorkOrder'
import { useTranslation } from 'react-i18next'
import useGoogleTranslation from 'src/services/useGoogleTranslation'
import type {
  WorkOrderItem,
  FromInventoryItem,
} from 'src/types/manageWorkOrder'

type WorkOrderTablesProps = {
  workOrderItem: WorkOrderItem[]
  workOrderId: number
}

type FromInventoryItemsResults = {
  data: FromInventoryItem[]
  isLoading: boolean
  error: string | null
}

export type CellProps = {
  title: string | React.ReactNode
  align?: 'left' | 'center' | 'right'
}

const HeaderCell = ({ title, align = 'center' }: CellProps) => {
  return (
    <TableCell sx={{ padding: '5px 0', margin: 0 }}>
      <Text
        align={align}
        fontStyle='normal normal 600 14px/14px Poppins;'
        opacity={0.5}
        upperCase={true}
      >
        {title}
      </Text>
    </TableCell>
  )
}

function InventoryTable({ workOrderItem, workOrderId }: WorkOrderTablesProps) {
  const {
    data: FromInventory,
    isLoading,
    error,
  } = useFromInventoryItems(workOrderId) as FromInventoryItemsResults
  const singleEntity = workOrderItem[0]
  const AddOnExtraServices = useGoogleTranslation(
    singleEntity.AddOnExtraServices
  )
  const { t } = useTranslation()
  const columns = [
    t('style name'),
    t('description'),
    t('color'),
    t('size'),
    t('quantity'),
    t('silva provided'),
    t('sku'),
  ]
  return (
    <>
      <Grid
        container
        justifyContent='center'
        alignItems='center'
        sx={{ backgroundColor: 'var(--color-gray-light)' }}
        height={70}
        width='95%'
        margin='10px'
        borderRadius={5}
      >
        <InfoSection
          title={t('vendor')}
          value={singleEntity.VendorName ?? '- -'}
          direction='column'
          size={2}
          titleFontStyle='normal normal bold 18px/14px Poppins'
          valueFontStyle='normal normal 600 18px/14px Poppins'
        />
        <InfoSection
          title={t('brand')}
          value={singleEntity.BrandName ?? '- -'}
          direction='column'
          size={2}
          titleFontStyle='normal normal bold 18px/14px Poppins'
          valueFontStyle='normal normal 600 18px/14px Poppins'
        />
        <InfoSection
          title={t('status')}
          value={singleEntity.InventoryStatus ?? '- -'}
          direction='column'
          size={2}
          titleFontStyle='normal normal bold 18px/14px Poppins'
          valueFontStyle='normal normal 600 18px/14px Poppins'
        />
        <InfoSection
          title={t('Add ONs & Extra Services')}
          value={AddOnExtraServices ?? '- -'}
          direction='column'
          size={5}
          titleFontStyle='normal normal bold 18px/14px Poppins'
          valueFontStyle='normal normal 600 18px/14px Poppins'
        />
      </Grid>
      <TableContainer
        sx={{
          width: '750px',
          padding: '13px 13px 0 13px',
          overflowY: 'auto',
          maxHeight: '330px',
        }}
      >
        <Table>
          <TableHead>
            <TableRow>
              {columns.map((column, index) => (
                <HeaderCell
                  key={index}
                  title={column}
                />
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {isLoading ? (
              <LoadingSpinner
                height='30vh'
                width='25vh'
              />
            ) : error ? (
              <ErrorMessage message={`${error}`} />
            ) : (
              FromInventory?.map((row, i) => (
                <InventoryRow
                  key={i}
                  row={row}
                />
              ))
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  )
}

export default InventoryTable
