import {
  BadRequestError,
  ForbiddenError,
  NotFoundError,
  UnauthorizedError,
} from './errorTypes'
import { Cookies } from 'react-cookie'

/**
 * @function handleResponse
 * @param {Object} response - The response object.
 * @description
 *   A handler for the fetch response Object
 * @return {Promise<T>} A promise containing the deserialized response body.
 * */
export async function handleResponse<TData>(
  response: Response
): Promise<TData> {
  const cookies = new Cookies()
  if (response.status === 401) {
    const loginPath = '/login'
    if (window.location.pathname !== loginPath) {
      cookies.remove('id_token', { path: '/' })
      window.location.href = loginPath
    }

    const error = new UnauthorizedError()
    throw error
  }

  if (response.status === 204) {
    return {} as TData
  }

  const res = await response.json()

  if (response.status === 400) {
    throw new BadRequestError(res.message)
  }

  if (response.status === 403) {
    throw new ForbiddenError(res.message)
  }

  if (response.status === 404) {
    throw new NotFoundError(res.message)
  }

  if (response.status < 200 || response.status >= 300) {
    throw new Error(
      `There has been an error. Response status: ${response.status}`
    )
  }

  return res
}

export async function fetchWrapper<TData, TBody = unknown>({
  method = 'GET',
  url,
  body,
  ...additionalOptions
}: {
  url: string
  method?: string
  body?: TBody
  headers?: Record<string, string>
  additionalOptions?: any
}): Promise<TData> {
  const cookies = new Cookies()
  const token = cookies.get('id_token')
  //more this to ENV
  const API_BASEURL = process.env.REACT_APP_API_BASEURL
  const _url = `${API_BASEURL}${url}`

  const options = {
    method: method,
    headers: {
      Accept: 'application/vnd.api+json ',
      // 'Content-Type': 'application/vnd.api+json',
      // Cache: 'no-cache',
      Authorization: token,
    },
    body: body && JSON.stringify(body), // body can be undefined, that's ok
    ...additionalOptions,
  }

  const response = await fetch(_url, options)
  return await handleResponse<TData>(response)
}
