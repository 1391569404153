import { Grid } from '@mui/material'
import { capitalizedCase } from 'src/utils/capitalizedCase'
import { Text } from 'src/components/common'
import LiveViewProgressBar from './LiveViewProgressBar'
import { formatDate } from 'src/utils/formatDate'
import type { workOrderPipleline } from 'src/types/workOrderPipeline'

type LiveViewTableBodyProps = {
  color: string
  customerpoColor: string
  orderDetails: workOrderPipleline
}

const LiveViewTableBody = ({
  color,
  customerpoColor,
  orderDetails,
}: LiveViewTableBodyProps) => {
  let statusTag
  switch (orderDetails.Flagged == null ? 0 : orderDetails.Flagged) {
    case 1:
      statusTag = 'flagged'
      break
    case 0:
      statusTag = 'inProduction'
      break
    default:
      statusTag = 'inQueue'
  }
  return (
    <Grid
      container
      alignItems='center'
      sx={{
        margin: '25px 0',
        padding: '20px',
        height: '100px',
        background: `linear-gradient(to right, ${customerpoColor} 1%, ${color} 1%)`,
        borderRadius: '10px',
      }}
    >
      <Grid
        item
        sx={{ width: '11%' }}
      >
        <Text
          fontStyle='normal normal 500 24px/14px Poppins'
          align='left'
        >
          {orderDetails.WorkOrderID ?? '- -'}
        </Text>
      </Grid>
      <Grid
        item
        sx={{ width: '12%' }}
      >
        <Text
          fontStyle='normal normal 500 24px/14px Poppins'
          align='left'
        >
          {orderDetails.JobName ?? '- -'}
        </Text>
      </Grid>
      <Grid
        item
        sx={{ width: '14%' }}
      >
        <Text
          fontStyle='normal normal 500 24px/14px Poppins'
          align='left'
        >
          {capitalizedCase(orderDetails.CustomerName) ?? '- -'}
        </Text>
      </Grid>
      <Grid
        item
        sx={{ width: '10%' }}
      >
        <Text
          fontStyle='normal normal 500 24px/14px Poppins'
          align='left'
        >
          {orderDetails.Quantity ?? '- -'}
        </Text>
      </Grid>
      <Grid
        item
        sx={{ width: '12%' }}
      >
        <Text
          fontStyle='normal normal 500 24px/14px Poppins'
          align='left'
        >
          {formatDate(orderDetails.EstimatedShippingDate) ?? '- -'}
        </Text>
      </Grid>
      <Grid
        item
        sx={{ width: '40%' }}
      >
        <LiveViewProgressBar 
          status={orderDetails.Status ?? '- -'}
          statusTag={statusTag ?? 'inQueue'}
          totalTasks={orderDetails.TotalStages ?? '- -'}
          currentTask={orderDetails.CurrentStageOrder ?? '- -'}
          currentTaskTag={orderDetails.StageLabel ?? '- -'}
          isLiveViewTable={true}
        />
      </Grid>
    </Grid>
  )
}

export default LiveViewTableBody
