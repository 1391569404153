import { useQuery } from 'react-query'
import { fetchWrapper } from 'src/utils/fetchWrapper'
import { WorkOrderItem } from 'src/types/manageWorkOrder'

export function useWorkOrderDetails(itemId: number) {
  const API_ENDPOINT = `/workorders/${itemId}`
  const queryKey = ['workOrderDetails', itemId]

  return useQuery(queryKey, async () => {
    return await fetchWrapper<WorkOrderItem[]>({
      method: 'GET',
      url: API_ENDPOINT,
    })
  })
}
