import { Grid, Box } from '@mui/material'
import React from 'react'
import NavBar from './NavBar'

type Props = { children: React.ReactNode }

function AppWrapper({ children }: Props) {
  const version = process.env.REACT_APP_VERSION || '1.0.0'

  return (
    <Grid
      container={true}
      direction='column'
    >
      <Grid
        item={true}
        sx={{ height: '100px' }}
      >
        <NavBar />
      </Grid>
      <Grid
        item={true}
        container={true}
        alignItems='flex-start'
        justifyContent='center'
        sx={{ height: 'calc(100vh - 100px)' }}
      >
        {children}
      </Grid>
      <Box
        sx={{
          position: 'fixed',
          left: 0,
          bottom: 0,
          padding: '8px',
          color: '#aed432',
          fontSize: '12px',
        }}
      >
        V {version}
      </Box>
    </Grid>
  )
}

export default AppWrapper
