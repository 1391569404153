import React from 'react'
import CircularProgress from '@mui/material/CircularProgress'

type LoadingSpinnerProps = {
  height?: string
  width?: string
}

const LoadingSpinner = ({ height, width }: LoadingSpinnerProps) => (
  <div
    style={{
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      height: height || '100vh',
      width: width || '100vw',
    }}
  >
    <CircularProgress />
  </div>
)

export default LoadingSpinner
