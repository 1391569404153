import React from 'react'
import { Box, TextField } from '@mui/material'
import { DialogAlert, FilledButton, Text } from 'src/components/common'
import { useUpdateInventoryQuantity } from 'src/hooks/ManageInventory'
import { useTranslation } from 'react-i18next'
import type { ManageInventoryDetails } from 'src/types/manageInventory'
import { convertToUTC } from 'src/utils/convertToUTC'

type UpdateButtonDialogProps = {
  inventoryDetails: ManageInventoryDetails
  isOpen: boolean
  closeDialog: () => void
}

export default function UpdateButtonDialog({
  isOpen,
  closeDialog,
  inventoryDetails,
}: UpdateButtonDialogProps) {
  const { t } = useTranslation()
  const { mutate } = useUpdateInventoryQuantity()
  const [quantityReceived, setQuantityReceived] = React.useState<number | ''>(
    inventoryDetails.QuantityReceived
  )
  const [error, setError] = React.useState<string | null>(null)

  React.useEffect(() => {
    setQuantityReceived(inventoryDetails.QuantityReceived)
    setError(null)
  }, [isOpen, inventoryDetails])

  const getReceivedStatus = (received: number, shipped: number) => {
    switch (true) {
      case received === shipped:
        return 'YES'
      case received === 0:
        return 'NO'
      case received > 0 && received < shipped:
        return 'PARTIAL'
      default:
        return '-'
    }
  }

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const regex = /^[0-9\b]+$/

    if (e.target.value === '' || regex.test(e.target.value)) {
      setQuantityReceived(
        e.target.value === '' ? '' : parseInt(e.target.value, 10)
      )
    }
    setError(null)
  }

  const handleSaveUpdate = () => {
    const updatedQuantity = Number(quantityReceived)
    if (
      isNaN(updatedQuantity) ||
      quantityReceived === '' ||
      updatedQuantity > inventoryDetails.QuantityShipped
    ) {
      setError(
        t(
          'Quantity Received cannot be empty and should be less than or equal to the shipped quantity'
        )
      )
      return
    }

    const receivedStatus = getReceivedStatus(
      updatedQuantity,
      inventoryDetails.QuantityShipped
    )

    const updatedItem = {
      id: inventoryDetails.Id,
      cartonNumber: inventoryDetails.CartonNumber,
      vendorId: inventoryDetails.VendorId,
      brandID: inventoryDetails.BrandId,
      warehouse: inventoryDetails.Warehouse,
      vendorShipDate: convertToUTC(inventoryDetails.VendorShipDate),
      vendorShipVia: inventoryDetails.VendorShipVia,
      vendorTrackingNumber: inventoryDetails.VendorTrackingNumber,
      vendorCustomerAccount: inventoryDetails.VendorCustomerAccount,
      salesOrder: inventoryDetails.SalesOrder,
      weight: inventoryDetails.Weight,
      catalogStyle: inventoryDetails.CatalogStyle,
      catalogColor: inventoryDetails.CatalogColor,
      catalogSize: inventoryDetails.CatalogSize,
      quantityShipped: inventoryDetails.QuantityShipped,
      receivedStatus: receivedStatus,
      receivedDate: convertToUTC(inventoryDetails.ReceivedDate),
      inventoryRemaining: inventoryDetails.InventoryRemaining,
      lastInventoryUpdate: inventoryDetails.LastInventoryUpdate,
      active: inventoryDetails.Active,
      quantityReceived: updatedQuantity,
      vendorName: inventoryDetails.VendorName,
      brandName: inventoryDetails.BrandName,
      customerPO: inventoryDetails.CustomerPO,
      alternativePO: inventoryDetails.AlternativePO,
    }
    mutate(updatedItem)
    closeDialog()
  }
  return (
    <DialogAlert
      isOpen={isOpen}
      handleClose={closeDialog}
      dialogTitle={t('Update Inventory')}
      headerWidth={225}
    >
      <Box
        display='flex'
        flexDirection='column'
        alignItems='center'
        justifyContent='center'
        height='50vh'
      >
        <Box pb={3}>
          <Text fontStyle='normal normal 600 32px/32px Poppins'>
            {t('Enter Quantity Received')}
          </Text>
        </Box>
        <Box pb={3}>
          <TextField
            value={quantityReceived}
            onChange={handleChange}
            // type='number'
            sx={{
              '& input': {
                width: '300px',
                height: '120px',
                textAlign: 'center',
                backgroundColor: '#A3A3A3',
                border: 'none',
                borderRadius: '40px',
                color: 'black',
                fontSize: '60px',
                fontWeight: '600',
              },
              '& fieldset': {
                border: 'none',
              },
              '&:hover fieldset': {
                border: 'none',
              },
            }}
          />
        </Box>
        <Box>{error && <Text color='red'>{error}</Text>}</Box>
        <FilledButton
          onClick={handleSaveUpdate}
          sx={{
            height: '70px',
            borderRadius: '8px',
            padding: 2,
          }}
          textProps={{
            fontStyle: 'normal normal 600 32px/32px Poppins',
            typographyProps: { padding: 0 },
          }}
        >
          {t('Save Update')}
        </FilledButton>
      </Box>
    </DialogAlert>
  )
}
