import React from 'react'
import { Box, Checkbox, FormControlLabel, SvgIcon } from '@mui/material'
import DocumentIcon from 'src/components/common/svg/DocumentIcon'
import { FlagIcon } from 'src/components/common/svg'
import { DialogAlert, FilledButton, Text, SuccessDailog } from '../../common'
import { useUpdateFlagged, useAddNotes } from 'src/hooks/ManageWorkOrder'
import { useTranslation } from 'react-i18next'

type DialogActionButtonsProps = {
  closeDialog: () => void
  handleSave: () => void
}

const DialogActionButtons = ({
  closeDialog,
  handleSave,
}: DialogActionButtonsProps) => {
  const { t } = useTranslation()
  return (
    <div style={{ display: 'flex', gap: 30 }}>
      <FilledButton
        sx={{
          margin: 0,
          height: '60px',
          width: 170,
          backgroundColor: 'rgba(255,255,255,0.25)',
        }}
        textProps={{
          fontStyle: 'normal normal bold 18px/14px Poppins',
          upperCase: true,
        }}
        onClick={closeDialog}
      >
        {t('cancel')}
      </FilledButton>
      <FilledButton
        sx={{ margin: 0, height: '60px', padding: 2 }}
        textProps={{
          fontStyle: 'normal normal bold 18px/14px Poppins',
          upperCase: true,
          typographyProps: { sx: { padding: 0 } },
        }}
        startIcon={
          <SvgIcon
            sx={{ color: '#FFF', paddingRight: 1 }}
            viewBox='-5 2 23 19'
          >
            <DocumentIcon />
          </SvgIcon>
        }
        onClick={handleSave}
      >
        {t('add note')}
      </FilledButton>
    </div>
  )
}

type AddNotesDialogProps = {
  isOpen: boolean
  closeDialog: () => void
  workOrderId: number
  flagged: number
  initialFlagChecked: boolean
}

export default function AddNotesDialog({
  isOpen,
  closeDialog,
  workOrderId,
  flagged,
  initialFlagChecked,
}: AddNotesDialogProps) {
  const { t } = useTranslation()
  const [showSuccessDialog, setShowSuccessDialog] = React.useState(false)
  const [isChecked, setIsChecked] = React.useState(false)
  const [notes, setNotes] = React.useState<string>('')
  const [error, setError] = React.useState<string>('')
  const handleChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    const value: string = e.currentTarget.value
    setNotes(value)
    setError('')
  }
  React.useEffect(() => {
    setNotes('')
    setError('')
    setIsChecked(initialFlagChecked)
  }, [initialFlagChecked])

  const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setIsChecked(event.target.checked)
  }

  const { mutate: update, status: updateStatus } = useUpdateFlagged(workOrderId)
  const { mutate: create, status: createStatus } = useAddNotes(workOrderId)
  const handleSave = async () => {
    try {
      if (!notes.trim()) {
        setError(t('Notes cannot be empty'))
        return
      }
      if (isChecked) {
        const newFlaggedValue = isChecked ? 1 : flagged
        await update({
          flagged: newFlaggedValue,
        })
      }
      await create({
        note: notes,
        active: 1,
        noteRead: 'true',
      })
      if (createStatus) {
        setShowSuccessDialog(true)
      }
      closeDialog()
      setNotes('')
    } catch (error) {
      console.error('Error saving note:', error)
      setError(t(`An error occurred. Please try again.`))
    }
  }

  const Label = React.useMemo(() => {
    return (
      <Box sx={{ display: 'flex' }}>
        <SvgIcon
          sx={{ color: '#FF0000' }}
          viewBox='0 -4 25 25'
        >
          <FlagIcon />
        </SvgIcon>
        <Text
          fontStyle='normal normal bold 18px/14px Poppins'
          upperCase={true}
          color='#FF0000'
        >
          {t('flag work order')}
        </Text>
      </Box>
    )
  }, [])

  const buildFlaggedNote = () => {
    if (isChecked && updateStatus) {
      return (
        <>
          {t('YOU HAVE')}
          <SvgIcon
            sx={{ marginLeft: 1, marginRight: 1, color: '#FF0000' }}
            viewBox='0 -4 25 25'
          >
            <FlagIcon />
          </SvgIcon>
          {t('FLAGGED THIS WORK ORDER')}
        </>
      )
    } else {
      return null
    }
  }

  return (
    <>
      <DialogAlert
        isOpen={isOpen}
        handleClose={closeDialog}
        dialogTitle={t('ADD A WORK ORDER NOTE')}
        headerWidth={290}
      >
        <textarea
          style={{
            width: 925,
            height: 168,
            backgroundColor: 'black',
            border: '1px solid #FFF',
            borderRadius: '4px',
            opacity: 0.5,
            color: '#FFF',
            font: 'normal normal normal 18px/16px Poppins',
            padding: 14,
            marginTop: '5px',
          }}
          name='note'
          onChange={handleChange as any}
          placeholder={t('Add Notes Here...')}
        />
        {error && (
          <Text
            fontStyle='normal normal bold 18px/14px Poppins'
            upperCase={true}
            color='#FF0000'
          >
            {error}
          </Text>
        )}
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            width: '100%',
            padding: '30px 0',
          }}
        >
          <FormControlLabel
            name='flagged'
            control={
              <Checkbox
                color='error'
                sx={{
                  color: '#FFF',
                  '& .MuiSvgIcon-root': {
                    font: 'normal normal normal 40px/14px Poppins',
                  },
                }}
                checked={isChecked}
                onChange={handleCheckboxChange}
              />
            }
            label={Label}
          />
          <DialogActionButtons
            closeDialog={closeDialog}
            handleSave={handleSave}
          />
        </Box>
      </DialogAlert>
      {showSuccessDialog && (
        <SuccessDailog
          SucessNote={t('Your Work Order Note was added successfully!')}
          FlaggedNote={buildFlaggedNote()}
          isOpen={showSuccessDialog}
          closeDialog={() => setShowSuccessDialog(false)}
        />
      )}
    </>
  )
}
