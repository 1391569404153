import { useQuery } from 'react-query'
import { fetchWrapper } from 'src/utils/fetchWrapper'
import { BlankCustomerInventory } from 'src/types/managePipelineTasks'

export function useBlankCustomerInvenotry(customerId: number) {
  const API_ENDPOINT = `/inventory/blank/${customerId}`
  return useQuery('blankInventory', async () => {
    return await fetchWrapper<BlankCustomerInventory[]>({
      method: 'GET',
      url: API_ENDPOINT,
    })
  })
}
