import React from 'react'
import { Box } from '@mui/material'
import DialogAlert from './DialogAlert'
import Text from './Text'

type SuccessDialogProps = {
  isOpen: boolean
  closeDialog: () => void
  SucessNote: string
  FlaggedNote: string | React.ReactElement | null
}

export default function SuccessDialog({
  isOpen,
  closeDialog,
  SucessNote,
  FlaggedNote,
}: SuccessDialogProps) {
  return (
    <DialogAlert
      isOpen={isOpen}
      handleClose={closeDialog}
    >
      <Box
        sx={{
          height: '257px',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <Text
          fontStyle='normal normal 600 27px/36px Poppins'
          color='primary'
        >
          {SucessNote}
        </Text>
        <Text
          fontStyle='normal normal 600 27px/36px Poppins'
          color='var(--color-red)'
        >
          {FlaggedNote}
        </Text>
      </Box>
    </DialogAlert>
  )
}
