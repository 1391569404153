import React from 'react'
import { useQuery } from 'react-query'
import { fetchWrapper } from 'src/utils/fetchWrapper'
import { Cookies } from 'react-cookie'

export function useActiveInventory() {
  const [token, setToken] = React.useState(null)

  React.useEffect(() => {
    const fetchToken = async () => {
      const cookies = new Cookies()
      const token = cookies.get('id_token')
      setToken(token)
    }

    fetchToken()
  }, [])

  return useQuery(
    'activeInventory',
    async () => {
      // Delay the execution of the fetch request until the token is available
      if (!token) {
        await new Promise((resolve) => setTimeout(resolve, 5000))
      }

      return fetchWrapper({
        method: 'GET',
        url: '/inventory/active',
      })
    },
    {
      retry: 2,
      staleTime: 30000,
      enabled: !!token,
    }
  )
}
