import React from 'react'

const CheckBoxIcon = () => {
  return (
    <svg version='1.2' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M37.3,29.7c0,4.1-3.3,7.4-7.4,7.4H8.5c-4.1,0-7.4-3.3-7.4-7.4V8.3c0-4.1,3.3-7.4,7.4-7.4h21.4
		c1,0,2.1,0.2,3,0.6c0.2,0.1,0.4,0.3,0.5,0.6c0.1,0.3,0,0.5-0.2,0.7l-1.3,1.3c-0.2,0.2-0.4,0.3-0.6,0.3c-0.1,0-0.2,0-0.2-0.1
		c-0.4-0.1-0.8-0.2-1.2-0.2H8.5C6.2,4.2,4.4,6,4.4,8.3v21.4c0,2.3,1.9,4.1,4.1,4.1h21.4c2.3,0,4.1-1.9,4.1-4.1v-6.5
		c0-0.2,0.1-0.4,0.2-0.6l1.6-1.6c0.2-0.2,0.4-0.3,0.6-0.3c0.1,0,0.2,0,0.3,0.1c0.3,0.1,0.5,0.4,0.5,0.7V29.7z M22.3,29.9
		c-0.8,0.8-2.1,0.8-2.9,0L8.3,18.8c-0.8-0.8-0.8-2.1,0-2.9l2.8-2.8c0.8-0.8,2.1-0.8,2.9,0l6.8,6.8L37.5,3.2c0.8-0.8,2.1-0.8,2.9,0
		L43.3,6c0.8,0.8,0.8,2.1,0,2.9L22.3,29.9z'
      />
    </svg>
  )
}

export default CheckBoxIcon
