import React from 'react'
import { Grid } from '@mui/material'
import { FilledButton } from '../common'
import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

type TasksButtonsContainerProps = {
  selectedTaskID: number | null
  onComplete: () => void
}

function TasksButtonsContainer({
  selectedTaskID,
  onComplete,
}: TasksButtonsContainerProps) {
  const { t } = useTranslation()
  const navigate = useNavigate()
  return (
    <Grid
      item
      container
      justifyContent='center'
      marginBottom={2}
      justifySelf='flex-end'
    >
      <FilledButton
        onClick={() => {
          navigate(-1)
        }}
        sx={{
          backgroundColor: 'rgba(255,255,255,0.25)',
          borderRadius: '8px',
          width: '200px',
          height: '100px',
          '& .MuiTypography-root': { padding: 1 },
          '&:focus': {
            backgroundColor: 'rgba(255,255,255,0.5)',
          },
        }}
        textProps={{ fontStyle: 'normal normal bold 24px/14px Poppins;' }}
      >
        {t('cancel')}
      </FilledButton>
      {Boolean(selectedTaskID) && (
        <FilledButton
          onClick={onComplete}
          sx={{
            background: '#AED432 0% 0% no-repeat padding-box;',
            borderRadius: '8px',
            height: '100px',
            padding: 2,
            '& .MuiTypography-root': { padding: 1 },
          }}
          textProps={{ fontStyle: 'normal normal bold 24px/14px Poppins;' }}
          buttonProps={{ id: 'completeTaskButton' }}
        >
          {t('complete')}
        </FilledButton>
      )}
    </Grid>
  )
}

export default TasksButtonsContainer
