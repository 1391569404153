import React from 'react'
import {
  LiveViewScrollableBodyContainer,
  LiveViewTableBody,
  LiveViewTableContainer,
  LiveViewTableFooter,
  LiveViewTableHeader,
} from 'src/components/WorkOrderLiveView'
import {
  ErrorMessage,
  HelmetComponent,
  LoadingSpinner,
} from 'src/components/common'
import { useLiveWorkOrder } from 'src/hooks/WorkOrderLiveView'
import type { workOrderPipleline } from 'src/types/workOrderPipeline'
import * as CryptoJS from 'crypto-js'

type LiveWorkOrderResults = {
  data: workOrderPipleline[]
  isLoading: boolean
  error: string | null
  refetch: () => void
}

function WorkOrderLiveView() {
  const {
    data: LiveWorkOrder,
    isLoading,
    error,
    refetch,
  } = useLiveWorkOrder() as LiveWorkOrderResults

  const handleRestart = () => {
    refetch()
  }

  const filteredWorkOrderLiveView = React.useMemo(() => {
    return LiveWorkOrder?.filter((order) => order.OnHold !== "Yes");
  }, [LiveWorkOrder]);
  const sortedWorkOrderLiveView = React.useMemo(() => {
    return filteredWorkOrderLiveView
      ?.slice()
      .sort((a, b) => b.WorkOrderID - a.WorkOrderID);
  }, [filteredWorkOrderLiveView]);

  function generateRandomColor(customerPO: string) {
    const hash = CryptoJS.SHA256(customerPO).toString();
    const randomColor = `#${hash.substring(0, 6).toUpperCase()}`;
    return randomColor;
  }

  const colorsMap: { [key: string]: string } = sortedWorkOrderLiveView
    ? sortedWorkOrderLiveView.reduce(
        (acc: { [key: string]: string }, order) => {
          const customerPO = order.CustomerPO
          const color = generateRandomColor(customerPO)
          acc[customerPO] = color
          return acc
        },
        {}
      )
    : {}

  return (
    <>
      <HelmetComponent
        title='Work Order Live View | Silva Production'
        description='description'
        content='Work Order Live View | Silva Production'
      />
      <LiveViewTableContainer>
        <LiveViewTableHeader />
        <LiveViewScrollableBodyContainer
          onRestart={handleRestart}
          isLoading={isLoading}
        >
          {isLoading ? (
            <LoadingSpinner
              height={'70vh'}
              width={'auto'}
            />
          ) : error ? (
            <ErrorMessage message={`${error}`} />
          ) : (
            sortedWorkOrderLiveView.map((details, index) => (
              <LiveViewTableBody
                key={details.WorkOrderID}
                orderDetails={details}
                customerpoColor={colorsMap[details.CustomerPO]}
                color={index % 2 === 0 ? '#4E565E' : '#697178'}
              />
            ))
          )}
        </LiveViewScrollableBodyContainer>
        <LiveViewTableFooter />
      </LiveViewTableContainer>
    </>
  )
}

export default WorkOrderLiveView
