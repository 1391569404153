import React from 'react'
import * as Yup from 'yup'
import { Grid } from '@mui/material'
import { DialogAlert, ErrorMessage, LoadingSpinner, Text } from '../../common'
import { FormWrapper, FilledFormButton } from '../../common/form'
import WorkDoneFormContent from '../WorkDoneFormContent'
import {
  useWorkDone,
  useAddWorkDone,
} from 'src/hooks/ManageWorkOrder/WorkDoneDialog'
import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { toast } from 'react-toastify'
import type { WorkDone } from 'src/types/manageWorkOrder'

export type WorkDoneFormValues = {
  styleID: string
  color: string
  size: string
  quantityDecorated: number
  decoratedWeight: number
  misprintsOrDefects: number
  quantityShipped: number
  decoratedRemaining: number
}

type WorkDoneResult = {
  data: WorkDone[]
  error: string | null
  isLoading: boolean
}

type AddNotesDialogProps = {
  isOpen: boolean
  closeDialog: () => void
  workOrderId: number
}

export default function WorkDoneDialog({
  isOpen,
  closeDialog,
  workOrderId,
}: AddNotesDialogProps) {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const {
    data: formDetails,
    isLoading,
    error,
  } = useWorkDone(workOrderId) as WorkDoneResult

  const { mutate } = useAddWorkDone(workOrderId)

  const initialValues: Record<string, WorkDoneFormValues> =
    React.useMemo(() => {
      return formDetails?.reduce((acc, curr) => {
        const initValues = {
          styleID: curr.CatalogStyle,
          color: curr.CatalogColor,
          size: curr.CatalogSize,
          quantityDecorated: null,
          decoratedWeight: null,
          misprintsOrDefects: null,
          quantityShipped: null,
          decoratedRemaining: null,
        }

        return { ...acc, [curr.Id]: initValues }
      }, {})
    }, [formDetails])

  const styleIDs = formDetails?.map((details) => details.Id) || []

  const WorkDoneFormSchema = (styleID: string) => {
    return Yup.object().shape({
      quantityDecorated: Yup.number().required(t('Required')),
      decoratedWeight: Yup.number().required(t('Required')),
      misprintsOrDefects: Yup.number()
        .required(t('Required'))
        .test(
          'misprints',
          t('Cannot be greater than Quantity Decorated'),
          function (value) {
            return value <= this.parent.quantityDecorated
          }
        )
        .test(
          'sumMisprints',
          t('Defected and Shipped cannot exceed Quantity Decorated'),
          function (value) {
            const quantityShipped = this.parent.quantityShipped || 0
            return value + quantityShipped <= this.parent.quantityDecorated
          }
        ),
      quantityShipped: Yup.number()
        .required(t('Required'))
        .test(
          'quantityShipped',
          t('Cannot be greater than Quantity Decorated'),
          function (value) {
            return value <= this.parent.quantityDecorated
          }
        )
        .test(
          'sumQuantityShipped',
          t('Defected and Shipped cannot exceed Quantity Decorated'),
          function (value) {
            const misprintsOrDefects = this.parent.misprintsOrDefects || 0
            return value + misprintsOrDefects <= this.parent.quantityDecorated
          }
        ),
      decoratedRemaining: Yup.number().test(
        'notNegative',
        t('Must not be negative'),
        function (value) {
          if (value === undefined) {
            return true
          }
          return value >= 0
        }
      ),
    })
  }

  const validationSchema = Yup.object().shape(
    (styleIDs || []).reduce((acc, styleID) => {
      acc[String(styleID)] = WorkDoneFormSchema(String(styleID))
      return acc
    }, {} as Record<string, any>)
  )
  return (
    <>
      <DialogAlert
        isOpen={isOpen}
        handleClose={closeDialog}
        paperProps={{
          sx: { position: 'absolute', bottom: '4rem', minWidth: '95%' },
        }}
      >
        <FormWrapper
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={async (formValues) => {
            try {
              const requests = Object.keys(formValues)
                .map((styleId) => {
                  const inventoryId = formDetails.find(
                    (item) => item.Id === Number(styleId)
                  )?.InventoryId
                  if (inventoryId) {
                    return {
                      inventoryId,
                      data: {
                        quantityDecorated:
                          formValues[styleId].quantityDecorated,
                        decoratedWeight: formValues[styleId].decoratedWeight,
                        misprintOrDefects:
                          formValues[styleId].misprintsOrDefects,
                        decoratedRemaining:
                          formValues[styleId].decoratedRemaining,
                        sku: formDetails.find(
                          (item) => item.Id === Number(styleId)
                        )?.SKU,
                        active: 1,
                      },
                    }
                  } else {
                    toast.error(t('Failed to Update work Order'))
                  }
                  return null
                })
                .filter(Boolean)

              await mutate(requests as any)
              navigate('/work-order-summary')
              closeDialog()
            } catch (error) {
              console.error('Error:', error)
            }
          }}
        >
          {isLoading ? (
            <LoadingSpinner
              height='70vh'
              width='125vh'
            />
          ) : error ? (
            <ErrorMessage message={error.toString()} />
          ) : (
            <Grid
              container
              direction='column'
              justifyContent='center'
              alignItems='center'
              minHeight={600}
              padding='2rem 0'
            >
              <Grid
                item
                justifySelf='flex-start'
              >
                <Text
                  upperCase={true}
                  fontStyle='normal normal 400 22px/22px Poppins'
                  color='var(--color-primary-green)'
                >
                  {t('enter details of work completed')}
                </Text>
              </Grid>

              <WorkDoneFormContent styleIDs={styleIDs} />

              <Grid
                item
                justifySelf='flex-end'
              >
                <FilledFormButton text={t('save')} />
              </Grid>
            </Grid>
          )}
        </FormWrapper>
      </DialogAlert>
    </>
  )
}
