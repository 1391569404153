import React from 'react'
import { Grid, Box, SvgIcon } from '@mui/material'
import CheckIcon from '@mui/icons-material/Check'
import { Text } from 'src/components/common'
import useGoogleTranslation from 'src/services/useGoogleTranslation'
import type { PipelineTasks } from 'src/types/managePipelineTasks'

type TaskCardProps = {
  setSelectedTaskID: React.Dispatch<React.SetStateAction<number | null>>
  setSelectedTaskOrder: React.Dispatch<React.SetStateAction<number>>
  Task: PipelineTasks
  stages: PipelineTasks[]
  taskID: number
  isTaskComplete?: boolean
  isClicked: boolean
  setIsClicked: Function
  setClickedRowIndex: Function
  clickedRowIndex: number | null
  index: number
}

const TaskCard = ({
  Task,
  stages,
  isTaskComplete,
  setSelectedTaskID,
  setSelectedTaskOrder,
  isClicked,
  setIsClicked,
  setClickedRowIndex,
  clickedRowIndex,
  index,
}: TaskCardProps) => {
  const WorkToBeDone = useGoogleTranslation(Task.WorkToBeDone)
  const handleFocus = React.useCallback(() => {
    if (isTaskComplete || !Task.Order) {
      return
    }

    if (stages.indexOf(Task) < stages.length - 1) {
      setSelectedTaskID(stages[stages.indexOf(Task) + 1].Stage_WTBDID) //WTBD ID of Next Task
    } else {
      setSelectedTaskID(stages[stages.indexOf(Task)].Stage_WTBDID)
    }
    setSelectedTaskOrder(stages.indexOf(Task) + 1)
    setClickedRowIndex(index)
    setIsClicked(true)
  }, [
    isTaskComplete,
    setSelectedTaskID,
    setSelectedTaskOrder,
    Task,
    stages,
    setIsClicked,
    index,
    setClickedRowIndex,
  ])

  const handleBlur = (e: React.FocusEvent<HTMLDivElement, Element>) => {
    if (e.relatedTarget?.id === 'completeTaskButton') {
      return
    }
    setSelectedTaskID(null)
    setIsClicked(false)
  }

  return (
    <Grid
      item
      xs={3}
      container
      justifyContent='center'
      alignItems='center'
      sx={{ aspectRatio: 1 }}
      padding={2}
    >
      <Box
        component='div'
        sx={{
          backgroundColor: isTaskComplete
            ? 'var(--color-primary-green)'
            : '#2A323C',
          border: '2px solid transparent',
          boxShadow: '0px 4px 8px #00000026',
          borderRadius: '15px',
          height: '100%',
          width: '100%',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          position: 'relative',
          '&:hover': {
            border: '2px solid var(--color-primary-green)',
          },
        }}
        tabIndex={0}
        onClick={handleFocus}
        onBlur={handleBlur}
      >
        {isTaskComplete && (
          <SvgIcon
            sx={{
              position: 'absolute',
              top: 5,
              left: 10,
              font: 'normal normal normal 48px/14px FontAwesome;',
            }}
          >
            <CheckIcon
              sx={{
                stroke: 'white',
                WebkitTextStrokeColor: 'white',
                strokeWidth: 2,
              }}
            />
          </SvgIcon>
        )}
        {!isTaskComplete && clickedRowIndex === index && isClicked && (
          <SvgIcon
            sx={{
              position: 'absolute',
              top: 5,
              left: 10,
              font: 'normal normal normal 48px/14px FontAwesome;',
            }}
          >
            <CheckIcon
              sx={{
                stroke: '#aed432',
                WebkitTextStrokeColor: 'white',
                strokeWidth: 2,
              }}
            />
          </SvgIcon>
        )}
        <Text
          fontStyle='normal normal medium 28px/28px Poppins'
          noWrap={false}
        >
          {WorkToBeDone}
        </Text>
      </Box>
    </Grid>
  )
}

export default TaskCard
