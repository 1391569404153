import { useQuery } from 'react-query'
import { fetchWrapper } from 'src/utils/fetchWrapper'
import type { PipelineTasks } from 'src/types/managePipelineTasks'

export function usePipelineStages(StageId: number) {
  const API_ENDPOINT = `/pipelines/stages/${StageId}/stageWTBD/list`
  const queryKey = ['PipelineTasks', StageId]

  return useQuery(queryKey, async () => {
    return await fetchWrapper<PipelineTasks[]>({
      method: 'GET',
      url: API_ENDPOINT,
    })
  })
}
