import React from "react";
import { Formik, Form, FormikValues } from "formik";
import { ObjectSchema } from "yup";

export type FormWrapperProps<T extends FormikValues> = {
  initialValues: T;
  validationSchema: ObjectSchema<T>;
  onSubmit: (values: T) => void;
  children: React.ReactNode;
};

const FormWrapper = <T extends FormikValues>({
  initialValues,
  validationSchema,
  onSubmit,
  children,
}: FormWrapperProps<T>) => {
  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={onSubmit}
      enableReinitialize={true}
    >
      <Form>{children}</Form>
    </Formik>
  );
};

export default FormWrapper;
