import React from 'react'

export const UpdateIcon = () => {
  return (
    <svg version='1.2' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M25.4,15.8c-1.4,5.9-6.3,10.1-12.5,10.1c-3.3,0-6.4-1.3-8.8-3.5L2,24.5c-0.2,0.2-0.5,0.3-0.8,0.3
		c-0.6,0-1.1-0.5-1.1-1.1v-7.5c0-0.6,0.5-1.1,1.1-1.1h7.5c0.6,0,1.1,0.5,1.1,1.1c0,0.3-0.1,0.6-0.3,0.8l-2.3,2.3
		c1.6,1.5,3.7,2.3,5.8,2.3c3,0,5.7-1.5,7.3-4.1c0.4-0.7,0.6-1.3,0.9-2c0.1-0.2,0.3-0.4,0.5-0.4h3.2c0.3,0,0.5,0.3,0.5,0.5
		C25.4,15.7,25.4,15.8,25.4,15.8z M25.9,9.8c0,0.6-0.5,1.1-1.1,1.1h-7.5c-0.6,0-1.1-0.5-1.1-1.1c0-0.3,0.1-0.6,0.3-0.8l2.3-2.3
		c-1.6-1.5-3.7-2.3-5.8-2.3c-3,0-5.7,1.5-7.3,4.1c-0.4,0.7-0.6,1.3-0.9,2c-0.1,0.2-0.3,0.4-0.5,0.4H1c-0.3,0-0.5-0.3-0.5-0.5
		c0,0,0-0.1,0-0.1C1.9,4.3,6.8,0.1,13,0.1c3.3,0,6.5,1.3,8.9,3.5L24,1.5c0.2-0.2,0.5-0.3,0.8-0.3c0.6,0,1.1,0.5,1.1,1.1V9.8z'
      />
    </svg>
  )
}
