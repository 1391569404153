import React from "react";
import { Card } from "@mui/material";

type CustomCardProps = {
  children: React.ReactNode;
  opacity: number;
};

function CardSubContainer({ children, opacity }: CustomCardProps) {
  return (
    <Card
      sx={{
        width: '1246px',
        height: '140px',
        background: `rgba(255,255,255,${opacity})`,
        padding: 0,
        marginBottom: '21px',
        '&:hover': {
          backgroundColor: 'rgba(255,0,0,0.05)',
        },
      }}
    >
      {children}
    </Card>
  );
}

export default CardSubContainer;
