import React from 'react'
import { Grid, Stack, CardHeader, CardContent } from '@mui/material'
import { Text } from 'src/components/common'
import { CardContainer } from '../common/Card'
import WorkOrderSummarySearchBar from './WorkOrderSummarySearchBar'
import { useTranslation } from 'react-i18next'
import type { WorkOrderSummaryDetails } from 'src/types/workOrderSummary'

type WorkFlowSummaryContainerProps = {
  children: React.ReactNode
  setFiltered: React.Dispatch<React.SetStateAction<WorkOrderSummaryDetails[]>>
  dataset: WorkOrderSummaryDetails[]
}

function WorkFlowSummaryContainer({
  children,
  setFiltered,
  dataset,
}: WorkFlowSummaryContainerProps) {
  const { t } = useTranslation()
  return (
    <CardContainer>
      <CardHeader
        title={
          <Grid container={true}>
            <Grid
              item={true}
              container={true}
              xs={7}
            >
              <Text
                fontStyle='normal normal 600 32px/32px Poppins'
                upperCase={true}
                align='start'
              >
                {t('Work order summary')}
              </Text>
            </Grid>
            <Grid
              item={true}
              container={true}
              xs={5}
              alignItems='center'
            >
              <Stack direction='row'>
                <Text fontStyle='normal normal 600 30px/30px Poppins'>
                  {t('Search')}
                </Text>
                <WorkOrderSummarySearchBar
                  setFiltered={setFiltered}
                  dataset={dataset}
                />
              </Stack>
            </Grid>
          </Grid>
        }
        sx={{ alignSelf: 'start', margin: '0 0 0 8px', padding: '9px' }}
      />
      <CardContent
        sx={{
          margin: 0,
          padding: 0,
          overflowY: 'auto',
          paddingRight: '15px',
        }}
      >
        {children}
      </CardContent>
    </CardContainer>
  )
}

export default WorkFlowSummaryContainer
