import { Grid } from '@mui/material'
import StartNotch from './StartNotch'
import EndNotch from './EndNotch'
import PipeLine from './PipeLine'

type LiveViewProgressBarProps = {
  statusTag: string
  totalTasks: number
  currentTask: number
  currentTaskTag: string
  isLiveViewTable?: boolean
  status?: string
}

const LiveViewProgressBar = ({
  totalTasks,
  currentTask,
  currentTaskTag,
  statusTag,
  isLiveViewTable,
  status,
}: LiveViewProgressBarProps) => {
  return (
    <Grid
      item
      container
      alignItems='center'
      justifyContent='flex-start'
      marginLeft='-5px'
      sx={{
        border: '3px solid #35404B',
        borderRadius: '30px',
        height: '56px',
        width: '100%',
        overflow: 'hidden',
      }}
    >
      <StartNotch taskTag={currentTaskTag} status={status} />
      <PipeLine
        pipeLineStatus={statusTag}
        currentTask={currentTask}
        totalTask={totalTasks}
        isLiveViewTable={isLiveViewTable}
      />
      <EndNotch totalTask={totalTasks} />
    </Grid>
  )
}
export default LiveViewProgressBar
