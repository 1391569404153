import React from 'react'
import ProtectedRoutes from './ProtectedRoutes'

type AuthWrapperTypes = {
  children: React.ReactNode
}

function AuthWrapper({ children }: AuthWrapperTypes) {
  const redirect = '/login'
  const asPath =
    window.location.pathname.split(' ')[0] === redirect
      ? ''
      : window.location.pathname.split(' ')

  const protectedRoutes = asPath

  return (
    <>
      {protectedRoutes.includes(window.location.pathname) ? (
        <ProtectedRoutes>{children}</ProtectedRoutes>
      ) : (
        children
      )}
    </>
  )
}

export default AuthWrapper
