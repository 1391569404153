import React from 'react'
import { styled } from '@mui/material/styles'
import FormGroup from '@mui/material/FormGroup'
import FormControlLabel from '@mui/material/FormControlLabel'
import Switch from '@mui/material/Switch'
import { useTranslation } from 'react-i18next'

const MaterialUISwitch = styled(Switch)(({ theme }) => ({
  width: 62,
  height: 34,
  padding: 7,
  '& .MuiSwitch-switchBase': {
    margin: 1,
    padding: 0,
    transform: 'translateX(6px)',
    '&.Mui-checked': {
      color: '#fff',
      transform: 'translateX(22px)',
      '& .MuiSwitch-thumb:before': {
        backgroundImage: `url('https://flagcdn.com/16x12/es.png')`,

        backgroundSize: '60%',
      },
      '& + .MuiSwitch-track': {
        opacity: 1,
        backgroundColor: theme.palette.mode === 'dark' ? '#8796A5' : '#aab4be',
      },
    },
  },
  '& .MuiSwitch-thumb': {
    backgroundColor: theme.palette.mode === 'dark' ? '#fff' : '#fff',
    width: 32,
    height: 32,
    '&:before': {
      content: "''",
      position: 'absolute',
      width: '100%',
      height: '100%',
      left: 0,
      top: 0,
      backgroundRepeat: 'no-repeat',
      backgroundPosition: 'center',
      backgroundImage: `url('https://flagcdn.com/16x12/us.png')`,

      backgroundSize: '60%',
    },
  },
  '& .MuiSwitch-track': {
    opacity: 1,
    backgroundColor: theme.palette.mode === 'dark' ? '#8796A5' : '#aab4be',
    borderRadius: 20 / 2,
  },
}))
const LANGUAGE_KEY = 'selectedLanguage'
export default function LanguageSwitcherMui() {
  const { i18n } = useTranslation()

  const storedLanguage = localStorage.getItem(LANGUAGE_KEY)
  const [checked, setChecked] = React.useState(storedLanguage === 'es')

  React.useEffect(() => {
    if (storedLanguage) {
      i18n.changeLanguage(storedLanguage)
    }
  }, [i18n, storedLanguage])

  const handleToggle = () => {
    const newChecked = !checked
    setChecked(newChecked)
    const newLanguage = newChecked ? 'es' : 'en'
    i18n.changeLanguage(newLanguage)
    localStorage.setItem(LANGUAGE_KEY, newLanguage)
  }

  return (
    <div className='d-inline'>
      <FormGroup>
        <FormControlLabel
          labelPlacement='start'
          control={
            <MaterialUISwitch
              sx={{ m: 1 }}
              checked={checked}
              onChange={handleToggle}
            />
          }
          label={checked ? 'SPANISH' : 'ENGLISH'}
        />
      </FormGroup>
    </div>
  )
}
