import React from 'react'
import { Stack, CardHeader, CardContent, Grid } from '@mui/material'
import { ManageInventorySearchBar } from 'src/components/ManageInventory'
import { Text } from 'src/components/common'
import { CardContainer } from '../common/Card'
import { useTranslation } from 'react-i18next'
import type { ManageInventoryDetails } from 'src/types/manageInventory'

type ManageInventoryContainerProps = {
  children: React.ReactNode
  setFiltered: React.Dispatch<React.SetStateAction<ManageInventoryDetails[]>>
  dataset: ManageInventoryDetails[]
}

function ManageInventoryContainer({
  children,
  setFiltered,
  dataset,
}: ManageInventoryContainerProps) {
  const { t } = useTranslation()
  return (
    <CardContainer>
      <CardHeader
        title={
          <Grid container={true}>
            <Grid
              item={true}
              container={true}
              xs={7}
            >
              <Text
                fontStyle='normal normal 600 32px/32px Poppins'
                upperCase={true}
                align='start'
              >
                {t('Manage Inventory')}
              </Text>
            </Grid>
            <Grid
              item={true}
              container={true}
              xs={5}
              alignItems='center'
            >
              <Stack direction='row'>
                <Text fontStyle='normal normal 600 30px/30px Poppins'>
                  {t('Search')}
                </Text>
                <ManageInventorySearchBar
                  setFiltered={setFiltered}
                  dataset={dataset}
                />
              </Stack>
            </Grid>
          </Grid>
        }
        sx={{ alignSelf: 'start', margin: '0 0 0 8px', padding: '9px' }}
      />
      <CardContent
        sx={{
          margin: 0,
          padding: 0,
          overflowY: 'auto',
          paddingRight: '15px',
        }}
      >
        {children}
      </CardContent>
    </CardContainer>
  )
}

export default ManageInventoryContainer
