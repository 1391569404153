import React from 'react'

export const PencilBoxIcon = () => {
  return (
    <svg version='1.2' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M35.1,29.2c0,3.9-3.2,7-7,7H7.7c-3.9,0-7-3.2-7-7V8.8c0-3.9,3.2-7,7-7h20.3c1,0,2,0.2,2.9,0.6
		c0.2,0.1,0.4,0.3,0.4,0.6c0,0.3,0,0.5-0.2,0.7L30,4.9c-0.2,0.2-0.5,0.3-0.8,0.2c-0.4-0.1-0.7-0.1-1.1-0.1H7.7
		c-2.2,0-3.9,1.8-3.9,3.9v20.3c0,2.2,1.8,3.9,3.9,3.9h20.3c2.2,0,3.9-1.8,3.9-3.9v-3.1c0-0.2,0.1-0.4,0.2-0.5l1.6-1.6
		c0.2-0.2,0.6-0.3,0.9-0.2c0.3,0.1,0.5,0.4,0.5,0.7V29.2z M39.8,13.5L23.4,30h-7v-7L32.8,6.5L39.8,13.5z M25.2,24.8l-3.7-3.7
		l-2.8,2.8v1.4H21v2.3h1.4L25.2,24.8z M32.4,10l-8.6,8.6c-0.2,0.2-0.2,0.6,0,0.8c0.2,0.2,0.6,0.2,0.8,0l8.6-8.6
		c0.2-0.2,0.2-0.6,0-0.8C32.9,9.8,32.6,9.8,32.4,10z M41.4,12l-7-7l2.3-2.2c0.9-0.9,2.4-0.9,3.3,0l3.7,3.7c0.9,0.9,0.9,2.4,0,3.3
		L41.4,12z'
      />
    </svg>
  )
}
