import { useQuery } from 'react-query'
import { fetchWrapper } from 'src/utils/fetchWrapper'
import type { NotesItems } from 'src/types/manageWorkOrder'

export function useNotesItems(itemId: number) {
  const API_ENDPOINT = `/notes/WorkOrder/${itemId}/list`
  return useQuery('notesItems', async () => {
    return await fetchWrapper<NotesItems[]>({
      method: 'GET',
      url: API_ENDPOINT,
    })
  })
}
