import React from 'react'
import { Grid } from '@mui/material'
import { Text } from 'src/components/common'

type StartNotchProps = { taskTag: string, status?: string }

export default function StartNotch({ taskTag, status }: StartNotchProps) {
  return (
    <Grid
      item
      justifySelf='flex-start'
      alignItems='center'
      sx={{
        backgroundColor: '#35404B',
        height: '50px',
        display: 'flex',
        position: 'relative',
        padding: '0 0 0 10px ',
        margin: 0,
        '&:after': {
          clipPath: 'path("M0,0 A50,50 0 0 1 15,25 A50,50 0 0 1 0,50 Z")',
          content: '""',
          width: '56px',
          height: '56px',
          background: '#35404B',
          position: 'absolute',
          right: '-55px',
          top: 0,
        },
      }}
    >
      <Text
        fontStyle='normal normal 600 24px/24px Poppins'
        upperCase={true}
        typographyProps={{ sx: { zIndex: 101 } }}
      >
         {status === 'Completed' ? 'Completed' : taskTag}
      </Text>
    </Grid>
  )
}
