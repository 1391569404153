import React from 'react'
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
} from 'react-router-dom'
import Login from './pages/LoginPage'
import ManageWorkOrder from './pages/ManageWorkOrder'
import ManageInventory from './pages/ManageInventory'
import WorkOrderListing from './pages/WorkOrderSummary'
import ManageTasks from './pages/ManagePipelineTasks'
import WorkOrderLiveView from './pages/WorkOrderLiveView'
import AuthWrapper from './components/Auth/AuthWrapper'
import AppWrapper from './components/AppWrapper'
import { useCookies } from 'react-cookie'
import { useTranslation } from 'react-i18next'
import { ToastContainer, toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import './styles/App.css'

function App() {
  const idToken = useCookies(['id_token'])[0]?.id_token
  const isLoggedIn = !!idToken
  const { i18n } = useTranslation()

  React.useEffect(() => {
    const storedLanguage = localStorage.getItem('selectedLanguage')
    if (storedLanguage) {
      i18n.changeLanguage(storedLanguage)
    }
  }, [i18n])

  return (
    <div className='app'>
      <Router>
        <Routes>
          <Route
            path='/login'
            element={
              isLoggedIn ? (
                <Navigate to='/' />
              ) : (
                <AuthWrapper>
                  <AppWrapper>
                    <Login />
                  </AppWrapper>
                </AuthWrapper>
              )
            }
          />
          <Route
            path='/'
            element={
              <AuthWrapper>
                <AppWrapper>
                  <ManageInventory />
                </AppWrapper>
              </AuthWrapper>
            }
          />
          <Route
            path='/work-order-summary'
            element={
              <AuthWrapper>
                <AppWrapper>
                  <WorkOrderListing />
                </AppWrapper>
              </AuthWrapper>
            }
          />
          <Route
            path='/work-order-live-view'
            element={<WorkOrderLiveView />}
          />
          <Route
            path='/manage-pipeline-tasks'
            element={
              <AuthWrapper>
                <AppWrapper>
                  <ManageTasks />
                </AppWrapper>
              </AuthWrapper>
            }
          />
          <Route
            path='/manage-work-order'
            element={
              <AuthWrapper>
                <AppWrapper>
                  <ManageWorkOrder />
                </AppWrapper>
              </AuthWrapper>
            }
          />

          <Route
            path='*'
            element={<Navigate to='/login' />}
          />
        </Routes>
      </Router>
      <ToastContainer />
    </div>
  )
}

export default App
