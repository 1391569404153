import React from 'react'

type TranslationResponse = {
  data: {
    translations: { translatedText: string }[]
  }
}

const useGoogleTranslation = (textToTranslate: string) => {
  const [translatedText, setTranslatedText] = React.useState<string | null>(
    null
  )

  const targetLanguage = localStorage.getItem('selectedLanguage') || 'en'
  const apiKey = process.env.REACT_APP_GOOGLE_TRANSLATION_API_KEY

  React.useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(
          `https://translation.googleapis.com/language/translate/v2?key=${apiKey}`,
          {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({
              q: textToTranslate,
              target: targetLanguage,
            }),
          }
        )

        if (!response.ok) {
          throw new Error(
            `Failed to fetch translation. Status: ${response.status}, ${response.statusText}`
          )
        }

        const data: TranslationResponse = await response.json()

        // Check if 'translations' array exists and is not empty
        if (
          data.data &&
          data.data.translations &&
          data.data.translations.length > 0
        ) {
          setTranslatedText(data.data.translations[0].translatedText)
        } else {
          throw new Error('Invalid translation response format')
        }
      } catch (error: any) {
        console.error('Error fetching translation:', error.message)
        setTranslatedText('- -') // Set a default value or handle the error appropriately
      }
    }

    fetchData()
  }, [textToTranslate, targetLanguage, apiKey])

  return translatedText
}

export default useGoogleTranslation
