import React from 'react'
import { Grid } from '@mui/material'
import { Text } from 'src/components/common'
import { capitalizedCase } from 'src/utils/capitalizedCase'
import { useTranslation } from 'react-i18next'
import useGoogleTranslation from 'src/services/useGoogleTranslation'
import type { WorkOrderItem } from 'src/types/manageWorkOrder'

type WorkFlowSummaryDetailsCardProps = {
  workOrderItem: WorkOrderItem[]
}

function TaskDetailsTopContainer({
  workOrderItem,
}: WorkFlowSummaryDetailsCardProps) {
  const PipelineLabel = useGoogleTranslation(workOrderItem[0].PipelineName)
  const StageLabel = useGoogleTranslation(workOrderItem[0].StageName)
  const { t } = useTranslation()
  const orderItems = workOrderItem[0]
  return (
    <Grid
      item
      container
      justifyContent='center'
      alignSelf='flex-start'
      direction='column'
      sx={{ marginBottom: '10px' }}
    >
      <Text
        fontStyle='normal normal 600 38px/32px Poppins'
        typographyProps={{ sx: { marginTop: '20px' } }}
      >
        {t('complete work').toUpperCase()}
      </Text>
      <Text
        fontStyle='normal normal 600 22px/22px Poppins'
        color='var(--color-primary-green)'
      >
        {capitalizedCase(orderItems?.CustomerName ?? '- -')}
      </Text>
      <Text fontStyle='normal normal 600 18px/22px Poppins'>
        {t('WORK ORDER #:').toUpperCase()}
        <span
          style={{ paddingLeft: '5px', color: 'var(--color-primary-green)' }}
        >
          {orderItems?.WorkOrderId ?? '- -'}
        </span>
      </Text>
      <Text
        fontStyle='italic normal normal 23px/26px Poppins'
        opacity={0.5}
        color='var(--color-primary-green)'
      >
        {capitalizedCase(`${PipelineLabel} > ${StageLabel}`)}
      </Text>
    </Grid>
  )
}

export default TaskDetailsTopContainer
