import React from 'react'

function ImageIcon() {
  return (
    <svg version='1.2' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M17.6,6.1v12.8c0,0.6-0.5,1.1-1.1,1.1h-15c-0.6,0-1.1-0.5-1.1-1.1V1.1C0.4,0.5,0.9,0,1.5,0h10
		c0.6,0,1.4,0.3,1.8,0.8l3.5,3.5C17.2,4.7,17.6,5.5,17.6,6.1z M16.1,7.1h-4.6c-0.6,0-1.1-0.5-1.1-1.1V1.4H1.9v17.1h14.3V7.1z
        M3.3,9.3c0-1.2,1-2.1,2.1-2.1s2.1,1,2.1,2.1c0,1.2-1,2.1-2.1,2.1S3.3,10.5,3.3,9.3z M14.7,17.1H3.3V15l2.1-2.1l1.4,1.4l4.3-4.3
		l3.6,3.6V17.1z M11.9,5.7H16c-0.1-0.2-0.2-0.4-0.2-0.5l-3.5-3.5c-0.1-0.1-0.3-0.2-0.5-0.2V5.7z'
      />
    </svg>
  )
}

export default ImageIcon
