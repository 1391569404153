import React from 'react'
import { Grid } from '@mui/material'
import TaskCard from './TaskCard'
import { LoadingSpinner, ErrorMessage } from 'src/components/common'
import {
  useBlankCustomerInvenotry,
  usePipelineStages,
} from 'src/hooks/PipelineTasks'
import type {
  PipelineTasks,
  BlankCustomerInventory,
} from 'src/types/managePipelineTasks'

type TasksCardContainerProps = {
  setSelectedTaskID: React.Dispatch<React.SetStateAction<number | null>>
  setSelectedTaskOrder: React.Dispatch<React.SetStateAction<number>>
  onLastOrderIdChange: React.Dispatch<React.SetStateAction<number>>
  StageID: number
  workToBeDoneID: number
  CustomerId: number
  workOrderId: number
  CurrentStageOrder: number
  TotalStages: number
  isClicked: boolean
  setIsClicked: Function
}

type PipelineStagesResults = {
  data: PipelineTasks[]
  error: string | null
  isLoading: boolean
}

type BlankCustomerInventoryResults = {
  data: BlankCustomerInventory[]
  error: string | null
  isLoading: boolean
}

function TasksCardContainer({
  setSelectedTaskID,
  StageID,
  workToBeDoneID,
  onLastOrderIdChange,
  setSelectedTaskOrder,
  CustomerId,
  workOrderId,
  CurrentStageOrder,
  TotalStages,
  isClicked,
  setIsClicked,
}: TasksCardContainerProps) {
  const [clickedRowIndex, setClickedRowIndex] = React.useState<number | null>(
    null
  )
  const {
    data: stages,
    isLoading: isStagesLoading,
    error: stagesError,
  } = usePipelineStages(StageID) as PipelineStagesResults

  const { data, isLoading, error } = useBlankCustomerInvenotry(
    CustomerId
  ) as BlankCustomerInventoryResults

  if (isLoading) return <LoadingSpinner height={'50vh'} />
  if (error) return <ErrorMessage message={`${error}`} />
  const InventoryQuantity = (
    data: BlankCustomerInventory[],
    workOrderId: number
  ): boolean => {
    if (CurrentStageOrder === TotalStages) {
      const filteredItems = data.filter((item) => item.WOID === workOrderId)

      const InventoryQuantity =
        filteredItems.length >= 1
          ? filteredItems.every((item) => item.Quantity === 0)
          : false
      return InventoryQuantity
    } else {
      return false
    }
  }
  const result = InventoryQuantity(data, workOrderId)
  if (isStagesLoading) return <LoadingSpinner height={'50vh'} />
  if (stagesError) return <ErrorMessage message={`${stagesError}`} />

  const lastOrderId = stages?.reduce((maxOrderId, task) => {
    return task.Order > maxOrderId ? task.Order : maxOrderId
  }, 0)
  onLastOrderIdChange(lastOrderId)

  return (
    <Grid
      item
      container
      alignSelf='center'
      justifyContent='center'
      width={stages?.length > 8 ? '55%' : '60%'}
    >
      {stages?.map((task, index) => (
        <TaskCard
          key={task.Order}
          index={index}
          Task={task}
          taskID={task.Order}
          isTaskComplete={task.Stage_WTBDID < workToBeDoneID || result}
          setSelectedTaskID={setSelectedTaskID}
          setSelectedTaskOrder={setSelectedTaskOrder}
          stages={stages}
          isClicked={isClicked}
          setIsClicked={setIsClicked}
          clickedRowIndex={clickedRowIndex}
          setClickedRowIndex={setClickedRowIndex}
        />
      ))}
    </Grid>
  )
}

export default TasksCardContainer
