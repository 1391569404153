import React from 'react'
import { CognitoUser, AuthenticationDetails } from 'amazon-cognito-identity-js'
import { useCookies } from 'react-cookie'
import Pool from 'src/UserPool'

type AuthContextType = {
  authenticate: (Username: any, Password: any) => Promise<void>
  getSession: () => Promise<void>
  logout: () => void
  loading: boolean
}

const AuthContext = React.createContext<AuthContextType | undefined>(undefined)

export const AuthProvider = ({ children }: any) => {
  const [cookies, setCookie] = useCookies(['id_token'])
  const [loading, setLoading] = React.useState(false)

  const getSession = async (): Promise<void> => {
    return await new Promise((resolve, reject) => {
      const user = Pool.getCurrentUser()
      if (user) {
        user.getSession((err: any, session: any) => {
          if (err) {
            reject(err)
          } else {
            resolve(session)
          }
        })
      } else {
        reject('User not logged in')
      }
    })
  }

  const authenticate = async (
    Username: string,
    Password: string
  ): Promise<void> => {
    const user = new CognitoUser({
      Username,
      Pool,
    })

    const authDetails = new AuthenticationDetails({
      Username,
      Password,
    })

    try {
      const successData = await new Promise((resolve, reject) => {
        user.authenticateUser(authDetails, {
          onSuccess: (data) => {
            const idToken = data.getIdToken().getJwtToken()
            setCookie('id_token', idToken, { path: '/', expires: new Date(0) })
            setLoading(false)

            user.refreshSession(data.getRefreshToken(), (err, session) => {
              if (err) {
                reject(err)
              } else {
                const refreshedIdToken = session.getIdToken().getJwtToken()
                setCookie('id_token', refreshedIdToken, { path: '/' })
                resolve(session)
              }
            })
          },
          onFailure: (err) => {
            setLoading(false)
            reject(new Error('Authentication failed: ' + err.message))
          },
          newPasswordRequired: (newPasswordData) => {
            reject(new Error('New password required: ' + newPasswordData))
          },
        })
      })

      return successData as any
    } catch (error) {
      throw error
    }
  }

  const logout = () => {
    const user = Pool.getCurrentUser()
    if (user) {
      user.signOut()
      setCookie('id_token', '', { path: '/', expires: new Date(0) })
    }
  }

  return (
    <AuthContext.Provider value={{ authenticate, getSession, logout, loading }}>
      {children}
    </AuthContext.Provider>
  )
}

export const useAuth = () => {
  const context = React.useContext(AuthContext)
  if (context === undefined) {
    throw new Error('useAuth must be used within a AuthProvider')
  }
  return context
}
