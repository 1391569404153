import React from 'react'
import { Helmet } from 'react-helmet'

type HelmetProps = {
  title?: string
  description?: string
  content?: string
}

export default function HelmetComponent({
  title,
  description,
  content,
}: HelmetProps) {
  return (
    <Helmet>
      <title>{title}</title>
      <meta
        name={description}
        content={content}
      />
    </Helmet>
  )
}
