import React from 'react'
import { Grid, TextField } from '@mui/material'
import { useField } from 'formik'
import type { GridProps, TextFieldProps } from '@mui/material'

type FormTextFieldProps = {
  name: string
  width?: GridProps['width']
  type?: TextFieldProps['type']
  readOnly?: boolean
  customNumberType?: boolean;
}

function FormTextField({
  name,
  width,
  type = 'text',
  readOnly = false,
  customNumberType = false,
}: FormTextFieldProps) {
  const [field, meta] = useField(name)
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    let value = e.target.value;
    // Unable to accept non-numeric numbers, If customNumberType is enable.
    if (customNumberType) {
      value = value.replace(/[^0-9]/g, '');
    }
    field.onChange({
      target: {
        name: e.target.name,
        value: value,
      },
    });
  };
  const hasError = meta.touched && !!meta.error;
  return (
    <Grid item width={width}>
      <TextField
        type={type}
        autoComplete='off'
        inputProps={{
          style: {
            textAlign: 'center',
            color: 'white',
            font: 'normal normal normal 18px/12px Poppins',
          },
        }}
        InputProps={{ readOnly: readOnly }}
        error={hasError}
        helperText={hasError ? meta.error : ''}
        {...field}
        onChange={handleChange}
      />
    </Grid>
  )
}

export default FormTextField
