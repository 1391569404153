import React from 'react'

function NoteIcon() {
  return (
    <svg
      width='600px'
      height='600px'
      xmlns='http://www.w3.org/2000/svg'
      viewBox='-5 -3 600 600'
      style={{ border: '1px solid red' }}
    >
      <g fill='var(--color-primary-green)'>
        <path
          d='M13.86,4.86v10.28c0,0.47-0.38,0.86-0.86,0.86H1c-0.47,0-0.86-0.38-0.86-0.86V0.86C0.14,0.39,0.53,0,1,0h8
				c0.47,0,1.13,0.28,1.46,0.61l2.79,2.78C13.58,3.72,13.86,4.38,13.86,4.86z M12.71,5.71H9c-0.47,0-0.86-0.38-0.86-0.86V1.14H1.29
				v13.71h11.43V5.71z M3.86,6.86h6.29c0.16,0,0.29,0.12,0.29,0.29v0.57C10.43,7.88,10.3,8,10.14,8H3.86C3.7,8,3.57,7.88,3.57,7.71
				V7.14C3.57,6.98,3.7,6.86,3.86,6.86z M10.43,9.43V10c0,0.16-0.12,0.29-0.29,0.29H3.86c-0.16,0-0.29-0.12-0.29-0.29V9.43
				c0-0.16,0.12-0.29,0.29-0.29h6.29C10.3,9.14,10.43,9.27,10.43,9.43z M10.43,11.71v0.57c0,0.16-0.12,0.29-0.29,0.29H3.86
				c-0.16,0-0.29-0.12-0.29-0.29v-0.57c0-0.16,0.12-0.29,0.29-0.29h6.29C10.3,11.43,10.43,11.55,10.43,11.71z M9.29,4.57h3.36
				c-0.05-0.15-0.13-0.3-0.2-0.37L9.65,1.41c-0.06-0.06-0.21-0.14-0.37-0.2V4.57z'
        />
      </g>
    </svg>
  )
}

export default NoteIcon
