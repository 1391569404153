import React from 'react'
import { Grid } from '@mui/material'
import { Text } from 'src/components/common'

type EndNotchProps = { totalTask: string | number }

export default function EndNotch({ totalTask }: EndNotchProps) {
  return (
    <Grid
      item
      justifySelf='flex-end'
      sx={{
        backgroundColor: '#35404B',
        height: '50px',
        display: 'flex',
        alignItems: 'center',
        position: 'relative',
        padding: '0 10px 0 0 ',
        margin: 0,
        '&:before': {
          content: '""',
          clipPath: 'path("M0,0 A50,50 0 0 1 15,25 A50,50 0 0 1 0,50 Z")',
          transform: 'rotateY(180deg)',
          width: '56px',
          height: '56px',
          background: '#35404B',
          position: 'absolute',
          left: '-55px',
          top: 0,
        },
      }}
    >
      <Text
        fontStyle='normal normal 600 24px/24px Poppins'
        upperCase={true}
        opacity={0.5}
        typographyProps={{ sx: { zIndex: 101 } }}
      >
        {totalTask}
      </Text>
    </Grid>
  )
}
