import { useMutation, useQueryClient } from 'react-query'
import { fetchWrapper } from 'src/utils/fetchWrapper'
import { toast } from 'react-toastify'
import { useTranslation } from 'react-i18next'
import type { AddNotes } from 'src/types/manageWorkOrder'

export function useAddNotes(itemId: number) {
  const API_ENDPOINT = `/notes/WorkOrder/${itemId}/create`
  const queryClient = useQueryClient()
  const { t } = useTranslation()

  const createNote = async (data: AddNotes) => {
    try {
      const response = await fetchWrapper<AddNotes>({
        method: 'POST',
        url: API_ENDPOINT,
        body: data,
      })
      toast.success(t('Note added successfully'))
      return response
    } catch (error) {
      console.error('API request failed:', error)
      toast.error(t('Failed to add note'))
      throw error
    }
  }

  return useMutation('notesItems', createNote, {
    onSuccess: () => {
      queryClient.invalidateQueries('notesItems')
    },
  })
}
