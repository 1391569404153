import { useQuery } from 'react-query'
import { fetchWrapper } from 'src/utils/fetchWrapper'
import { WorkOrderSummaryDetails } from 'src/types/workOrderSummary'

const API_ENDPOINT = '/workorders/summary/list'

export function useActiveWorkOrders() {
  return useQuery('activeWorkOrders', async () => {
    return await fetchWrapper<WorkOrderSummaryDetails[]>({
      method: 'GET',
      url: API_ENDPOINT,
    })
  })
}
