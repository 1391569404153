import { useMutation, useQueryClient } from 'react-query'
import { fetchWrapper } from 'src/utils/fetchWrapper'
import { toast } from 'react-toastify'
import { useTranslation } from 'react-i18next'

async function updateInventoryQuantity(item: any, translate: Function) {
  try {
    await fetchWrapper({
      method: 'PUT',
      url: `/inventory/${item.id}/update`,
      body: item,
    })
    toast.success(translate('Inventory quantity updated successfully'))
    // return updatedItem
  } catch (error) {
    console.error('API request failed:', error)
    toast.error(translate('Failed to update inventory quantity'))
    throw error
  }
}

export function useUpdateInventoryQuantity() {
  const queryClient = useQueryClient()
  const { t } = useTranslation()

  return useMutation<void, Error, any>(
    (item) => updateInventoryQuantity(item, t),
    {
      onSuccess: () => {
        queryClient.invalidateQueries('activeInventory')
      },
    }
  )
}
