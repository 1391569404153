import React from "react";
import { Card, CardContent, Grid } from "@mui/material";
import { ErrorMessage, LoadingSpinner } from "src/components/common";
import { useDecorationItems } from "src/hooks/ManageWorkOrder";
import type { DecorationItems } from "src/types/manageWorkOrder";
import DecorationCard from "./DecorationCard";

type DecorationsListingProps = {
  workOrderId: number;
  onRowClick: (row: string) => void;
  stopAutoPlay: boolean;
  setStopAutoPlay: Function;
};

type DecorationItemsResults = {
  data: DecorationItems[];
  isLoading: boolean;
  error: string | null;
};

export type CellProps = {
  title: string | number;
  align?: any;
};

function DecorationListing({
  workOrderId,
  onRowClick,
  stopAutoPlay,
  setStopAutoPlay,
}: DecorationsListingProps) {
  const [clickedRowIndex, setClickedRowIndex] = React.useState<number | null>(
    null
  );

  const handleRowClick = (imageUrl: string) => {
    onRowClick(imageUrl);
  };

  const {
    data: DecorationItems,
    isLoading,
    error,
  } = useDecorationItems(workOrderId) as DecorationItemsResults;
  return (
    <Card sx={{padding: '0px 16px 0px 16px', boxShadow: 'none'}}>
      <CardContent
        sx={{
          overflowY: "auto",
          maxHeight: "430px",
          padding: '0px !important',
        }}
      >
        {isLoading ? (
          <LoadingSpinner height="37vh" width="20vh" />
        ) : error ? (
          <ErrorMessage message={`${error}`} />
        ) : (
          <Grid container rowGap={2}>
            {DecorationItems?.map((row, index) => (
              <Grid item xs={12} key={row.Id} px={1}>
                <DecorationCard
                  index={index}
                  data={row}
                  onClick={() => handleRowClick(row.ArtDescriptionLink)}
                  stopAutoPlay={stopAutoPlay}
                  setStopAutoPlay={setStopAutoPlay}
                  clickedRowIndex={clickedRowIndex}
                  setClickedRowIndex={setClickedRowIndex}
                />
              </Grid>
            ))}
          </Grid>
        )}
      </CardContent>
    </Card>
  );
}

export default DecorationListing;
