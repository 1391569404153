import { useQuery } from 'react-query'
import { fetchWrapper } from 'src/utils/fetchWrapper'
import type { DecorationItems } from 'src/types/manageWorkOrder'

export function useDecorationItems(itemId: number) {
  const API_ENDPOINT = `/decorations/${itemId}`
  return useQuery('decorationItems', async () => {
    return await fetchWrapper<DecorationItems[]>({
      method: 'GET',
      url: API_ENDPOINT,
    })
  })
}
