import { CognitoUserPool } from 'amazon-cognito-identity-js'

const poolData = {
  UserPoolId: 'us-east-2_NmvJUgjxI',
  ClientId: '4mmbqi7e33ku9v4i16j3kotulq',
}

const userPool = new CognitoUserPool(poolData)

export default userPool
