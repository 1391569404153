import React from 'react'
import { Box } from '@mui/material'
import Text from './Text'

type ErrorMessageProps = {
  message: string
}

const ErrorMessage = ({ message }: ErrorMessageProps) => {
  return (
    <Box style={{ textAlign: 'center', padding: '20px' }}>
      <Text
        fontStyle='normal normal 600 16px/16px Poppins'
        upperCase={true}
        color='red'
      >
        {message}
      </Text>
    </Box>
  )
}

export default ErrorMessage
