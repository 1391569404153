import React from 'react'
import { TableCell, TableRow, SvgIcon } from '@mui/material'
import Text from '../../../common/Text'
import { ImageIcon } from '../../../common/svg'
import { formatDate } from 'src/utils/formatDate'
import { capitalizedCase } from 'src/utils/capitalizedCase'
import useGoogleTranslation from 'src/services/useGoogleTranslation'
import ReactLinkify from 'react-linkify'
import type { CellProps } from './NotesTable'

const RowCell = ({ title, icon, width }: CellProps) => {
  return (
    <TableCell
      sx={{
        margin: 0,
        paddingLeft: 0,
        borderBottom: '1px solid rgba(255,255,255,0.25)',
        width,
      }}
    >
      <div style={{ ...(Boolean(icon) && { display: 'flex' }) }}>
        {icon ? icon : <></>}
        <Text
          align='left'
          fontStyle='normal normal medium 14px/56px Poppins'
          typographyProps={{ flexWrap: 'wrap', whiteSpace: 'unset' }}
        >
          <ReactLinkify
            componentDecorator={(decoratedHref, decoratedText, key) => (
              <a
                href={decoratedHref}
                key={key}
                target='_blank'
                rel='noopener noreferrer'
                style={{
                  color: 'var(--color-primary-green)',
                  textDecoration: 'none',
                  textTransform: 'lowercase',
                }}
              >
                {decoratedText}
              </a>
            )}
          >
            {capitalizedCase(title)}
          </ReactLinkify>
        </Text>
      </div>
    </TableCell>
  )
}

const NotesRow = ({ row }: any) => {
  const Note = useGoogleTranslation(row.Note)
  return (
    <TableRow key={row.NoteId}>
      <RowCell
        title={formatDate(row.CreatedDate) ?? '- -'}
        width='100px'
      />
      <RowCell
        title={row.Name ?? '- -'}
        width='130px'
        icon={
          <SvgIcon
            sx={{
              color: '#FFF',
              opacity: 0.25,
              height: '30px',
            }}
            viewBox='-7 -5 25 25'
          >
            <ImageIcon />
          </SvgIcon>
        }
      />
      <RowCell
        title={Note ?? '- -'}
        width='300px'
      />
    </TableRow>
  )
}

export default NotesRow
