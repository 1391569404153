import React from 'react'

function RawIcon() {
  return (
    <svg
      width='400px'
      height='400px'
      xmlns='http://www.w3.org/2000/svg'
      viewBox='1 -5 500 500'
    >
      <g fill='var(--color-primary-green)'>
        <path
          d='M26.62,0.21c-0.17-0.19-0.44-0.24-0.65-0.13l-5.22,2.15c-0.06,0.03-0.12,0.06-0.17,0.11l-3.08,2.92H9.48
          L6.4,2.34C6.35,2.3,6.29,2.26,6.23,2.23L1.01,0.08c-0.24-0.1-0.52-0.01-0.67,0.2l0,0C0.19,0.5,0.22,0.79,0.39,0.98l4.8,5.06v13.38
          c0,0.3,0.25,0.55,0.55,0.55h15.49c0.3,0,0.55-0.25,0.55-0.55V6.04l4.79-5.04c0.11-0.1,0.18-0.23,0.19-0.38
          C26.77,0.47,26.72,0.33,26.62,0.21z M7.86,5.26H5.99l-3-3.16l2.73,1.12L7.86,5.26z M12.57,9.38V6.37h1.84v3.01H12.57z
          M12.01,10.48h2.95c0.3,0,0.55-0.25,0.55-0.55V6.37h5.16v12.5H6.3V6.37h5.15v3.56C11.46,10.24,11.71,10.48,12.01,10.48z M24,2.1
          l-3,3.16h-1.88l2.15-2.04L24,2.1z'
        />
      </g>
    </svg>
  )
}

export default RawIcon
