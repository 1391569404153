import React from 'react'
import { Dialog, DialogTitle, IconButton, Box } from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'
import Text from './Text'
import type { DialogProps, PaperProps } from '@mui/material'

type DialogAlertProps = {
  isOpen: boolean
  handleClose: () => void
  dialogTitle?: React.ReactNode | string
  children: React.ReactNode | string
  headerWidth?: number
  containerProps?: Omit<DialogProps, 'children' | 'open' | 'onClose'>
  paperProps?: PaperProps,
  showCloseIcon?: Boolean,
  style?: {
    boxPadding: string
  }
}

function DialogAlert({
  isOpen,
  handleClose,
  dialogTitle,
  children,
  headerWidth,
  containerProps = {},
  paperProps = {},
  showCloseIcon= true,
  style
}: DialogAlertProps) {
  return (
    <Dialog
      open={isOpen}
      onClose={handleClose}
      PaperProps={paperProps}
      {...containerProps}
    >
     {showCloseIcon && <IconButton
        aria-label='close'
        onClick={handleClose}
        sx={{
          position: 'absolute',
          right: 8,
          top: 8,
          color: 'var(--color-white)',
        }}
      >
        <CloseIcon
          sx={{
            stroke: 'white',
            WebkitTextStrokeColor: 'white',
            strokeWidth: 2,
          }}
        />
      </IconButton>}
      {Boolean(dialogTitle) && (
        <DialogTitle id='dialog'>
          <Text
            align='left'
            fontStyle='normal normal 600 22px/22px Poppins'
            upperCase={true}
            typographyProps={{
              sx: {
                borderBottom: '5px solid white',
                width: headerWidth || undefined,
                padding: '5px 0 10px 0',
              },
            }}
          >
            {dialogTitle}
          </Text>
        </DialogTitle>
      )}

      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          padding: style?.boxPadding || '0 25px',
        }}
      >
        {children}
      </Box>
    </Dialog>
  )
}

export default DialogAlert
