import Button from '@mui/material/Button'
import React from 'react'
import Text from '../Text'
import type { ButtonProps } from '@mui/material/Button'
import type { TextProps } from '../Text'

type OutlinedButtonProps = {
  children: React.ReactNode
  onClick: () => void
  startIcon?: React.ReactNode
  color?: 'primary' | 'error'
  textProps?: Omit<TextProps, 'children'>
  buttonProps?: Omit<ButtonProps, 'sx'>
  sx?: ButtonProps['sx']
}

function OutlinedButton({
  children,
  onClick,
  startIcon,
  color = 'primary',
  textProps = {},
  buttonProps = {},
  sx = {},
}: OutlinedButtonProps) {
  return (
    <Button
      variant='outlined'
      color={color}
      startIcon={startIcon}
      size='large'
      sx={{
        margin: 2,
        borderRadius: '6px',
        '& .MuiButton-startIcon': { margin: 0, padding: 0 },
        '& .MuiTypography-root': { margin: 0, padding: 0 },
        ...sx,
      }}
      onClick={onClick}
      {...buttonProps}
    >
      <Text {...textProps}>{children}</Text>
    </Button>
  )
}

export default OutlinedButton
