import React from "react";
import { Card } from "@mui/material";

type CustomCardProps = {
  children: React.ReactNode;
};

function CardContainer({ children }: CustomCardProps) {
  return (
    <Card
      sx={{
        width: "1318px",
        height: "867px",
        boxShadow: "0px 4px 8px #00000026",
        display: "flex",
        flexDirection: "column",
        justifyContent: "flex-start",
        alignItems: "center",
        marginTop: 4,
      }}
    >
      {children}
    </Card>
  );
}

export default CardContainer;
