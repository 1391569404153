import { useQuery } from 'react-query'
import { fetchWrapper } from 'src/utils/fetchWrapper'
import type { workOrderPipleline } from 'src/types/workOrderPipeline'

const API_ENDPOINT = '/workorders/active/list'

export function useLiveWorkOrder() {
  return useQuery('LiveWorkOrder', async () => {
    return await fetchWrapper<workOrderPipleline[]>({
      method: 'GET',
      url: API_ENDPOINT,
      //additional header only for this api.
      headers: {
        Origin: 'https://staging.portal.silvaorder.com',
      },
    })
  })
}
