import React from 'react'
import { Grid } from '@mui/material'
import FieldsPerID from './FieldsPerID'
import { Text } from 'src/components/common'
import { useTranslation } from 'react-i18next'

const WorkDoneFormHeader = () => {
  const { t } = useTranslation()
  return (
    <Grid
      container
      gap={3}
      wrap='nowrap'
    >
      <Grid
        item
        container
        wrap='nowrap'
        width='35%'
      >
        <Grid
          item
          container
          justifyContent='center'
          width='40%'
        >
          <Text
            upperCase={true}
            fontStyle='normal normal normal Poppins'
            typographyProps={{
              noWrap: false,
              alignSelf: 'center',
            }}
          >
            {t('style id')}
          </Text>
        </Grid>
        <Grid
          item
          container
          justifyContent='center'
          width='40%'
        >
          <Text
            upperCase={true}
            fontStyle='normal normal normal Poppins'
            typographyProps={{
              noWrap: false,
              alignSelf: 'center',
            }}
          >
            {t('color')}
          </Text>
        </Grid>
        <Grid
          item
          container
          justifyContent='center'
          width='20%'
        >
          <Text
            upperCase={true}
            fontStyle='normal normal normal Poppins'
            typographyProps={{
              noWrap: false,
              alignSelf: 'center',
            }}
          >
            {t('size')}
          </Text>
        </Grid>
      </Grid>
      <Grid
        item
        container
        wrap='nowrap'
        width='50%'
      >
        <Grid
          item
          container
          justifyContent='center'
          width='25%'
        >
          <Text
            upperCase={true}
            fontStyle='normal normal normal Poppins'
            typographyProps={{
              noWrap: false,
              alignSelf: 'center',
            }}
          >
            {t('Quantity Decorated')}
          </Text>
        </Grid>
        <Grid
          item
          container
          justifyContent='center'
          width='25%'
        >
          <Text
            upperCase={true}
            fontStyle='normal normal normal Poppins'
            typographyProps={{
              noWrap: false,
              alignSelf: 'center',
            }}
          >
            {t('Decorated Weight')}
          </Text>
        </Grid>
        <Grid
          item
          container
          justifyContent='center'
          width='25%'
        >
          <Text
            upperCase={true}
            fontStyle='normal normal normal Poppins'
            typographyProps={{
              noWrap: false,
              alignSelf: 'center',
            }}
          >
            {t('Misprints Or Defects')}
          </Text>
        </Grid>
        <Grid
          item
          container
          justifyContent='center'
          width='25%'
        >
          <Text
            upperCase={true}
            fontStyle='normal normal normal Poppins'
            typographyProps={{
              noWrap: false,
              alignSelf: 'center',
            }}
          >
            {t('Quantity Shipped')}
          </Text>
        </Grid>
      </Grid>
      <Grid
        item
        container
        wrap='nowrap'
        width='10%'
      >
        <Grid
          item
          container
          justifyContent='center'
        >
          <Text
            upperCase={true}
            fontStyle='normal normal normal Poppins'
            typographyProps={{
              noWrap: false,
              alignSelf: 'center',
            }}
          >
            {t('Decorations Remaining')}
          </Text>
        </Grid>
      </Grid>
    </Grid>
  )
}

type WordDoneFormProps = {
  styleIDs: any
}

function WordDoneForm({ styleIDs }: WordDoneFormProps) {
  return (
    <Grid
      item
      flex={1}
    >
      <WorkDoneFormHeader />
      <Grid
        container
        gap={4}
      >
        {styleIDs?.map((styleID: any) => (
          <FieldsPerID
            key={styleID}
            styleID={styleID}
          />
        ))}
      </Grid>
    </Grid>
  )
}

export default WordDoneForm
