import React from 'react'
import { Box, Grid } from '@mui/material'
import {
  FilledButton,
  Text,
  DialogAlert,
  HelmetComponent,
  LanguageSwitcherMui,
} from '../components/common'
import { useDialog } from 'src/hooks/common'
import PinInput from 'react-pin-input'
import { useNavigate } from 'react-router-dom'
import { useAuth } from 'src/providers/AuthContext'
import { useTranslation } from 'react-i18next'

function LoginPage() {
  const { authenticate } = useAuth()
  const { t } = useTranslation()
  const navigate = useNavigate()
  const WrongCredentialsDialog = useDialog()
  const EmptyCredentialsDialog = useDialog()
  const [employeeId, setEmployeeID] = React.useState<string>('')
  const [employeePIN, setEmployeePIN] = React.useState<string>('')

  const handleLogin = async () => {
    try {
      if (!employeeId || !employeePIN) {
        EmptyCredentialsDialog.openDialog()
        return
      }
      if (employeeId && employeePIN) {
        await authenticate(employeeId, employeePIN)
        navigate('/')
      }
    } catch (err) {
      console.log(err)
      WrongCredentialsDialog.openDialog()
    }
  }

  const EmployeeIdSection = React.useCallback(() => {
    return (
      <Box marginTop={2}>
        <Text fontStyle={'normal normal normal 18px/12px Poppins'}>
          {t('Enter 4-digit Employee ID')}
        </Text>
        <Grid
          container={true}
          gap={2}
          marginTop={2}
        >
          <PinInput
            length={4}
            initialValue={employeeId}
            onChange={(value, index) => {
              setEmployeeID(value)
            }}
            type='numeric'
            inputMode='number'
            style={{ padding: '10px', display: 'flex' }}
            inputStyle={{
              margin: '0px 12px',
              backgroundColor: 'rgba(77,86,96,0.25)',
              width: 78,
              height: 78,
              color: 'var(--color-white)',
              borderRadius: '4px 4px 0px 0px',
              border: 'none',
              font: 'normal normal bold 54px/14px Poppins',
            }}
            inputFocusStyle={{ borderColor: 'var(--color-white)' }}
            onComplete={(value) => {
              setEmployeeID(value)
            }}
            regexCriteria={/^[ A-Za-z0-9_@./#&+-]*$/}
          />
        </Grid>
      </Box>
    )
  }, [])

  const EmployeePinSection = React.useCallback(() => {
    return (
      <Box marginTop={2}>
        <Text fontStyle={'normal normal normal 18px/12px Poppins'}>
          {t('Enter 6-digit PIN')}
        </Text>
        <Grid
          container={true}
          gap={2}
          marginTop={2}
        >
          <PinInput
            length={6}
            initialValue={employeePIN}
            onChange={(value, index) => {
              setEmployeePIN(value)
            }}
            type='numeric'
            inputMode='number'
            style={{ padding: '10px', display: 'flex' }}
            inputStyle={{
              margin: '0px 12px',
              backgroundColor: 'rgba(77,86,96,0.25)',
              width: 78,
              height: 78,
              color: 'var(--color-white)',
              borderRadius: '4px 4px 0px 0px',
              font: 'normal normal bold 54px/14px Poppins',
              border: 'none',
            }}
            inputFocusStyle={{
              borderColor: 'var(--color-white)',
            }}
            onComplete={(value) => {
              setEmployeePIN(value)
            }}
            secret
            secretDelay={10}
            regexCriteria={/^[ A-Za-z0-9_@./#&+-]*$/}
          />
        </Grid>
      </Box>
    )
  }, [])

  return (
    <>
      <HelmetComponent
        title='Login | Silva Production'
        description='description'
        content='Login | Silva Production'
      />
      <Grid
        container={true}
        alignItems='center'
        justifyContent='center'
        flexDirection='column'
        sx={{ position: 'absolute', top: 260 }}
      >
        <Box
          style={{
            position: 'absolute',
            right: 200,
            top: -80,
          }}
        >
          <LanguageSwitcherMui />
        </Box>
        <Box>
          <Text
            upperCase={true}
            fontStyle='normal normal 600 32px/48px Poppins'
          >
            {t('login')}
          </Text>
        </Box>
        <EmployeeIdSection />
        <EmployeePinSection />
        <Box marginTop={8}>
          <FilledButton
            onClick={handleLogin}
            textProps={{
              fontStyle: 'normal normal bold 24px/24px Poppins',
              upperCase: true,
            }}
            sx={{ padding: '7px 84px' }}
          >
            {t('login')}
          </FilledButton>
        </Box>
        <DialogAlert
          isOpen={WrongCredentialsDialog.isOpen}
          handleClose={WrongCredentialsDialog.closeDialog}
        >
          <Box
            sx={{
              height: '257px',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <Text
              fontStyle='normal normal 600 27px/36px Poppins'
              color='var(--color-red)'
            >
              {t('Your 4-digit User ID or PIN is incorrect.')}
            </Text>
            <Text
              fontStyle='normal normal normal 27px/36px Poppins'
              color='var(--color-gray-light)'
            >
              {t('Please contact your Supervisor or Human Resources.')}
            </Text>
          </Box>
        </DialogAlert>
        <DialogAlert
          isOpen={EmptyCredentialsDialog.isOpen}
          handleClose={EmptyCredentialsDialog.closeDialog}
        >
          <Box
            sx={{
              height: '257px',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <Text
              fontStyle='normal normal 600 27px/36px Poppins'
              color='var(--color-red)'
            >
              {t("User ID or PIN can't be empty.")}
            </Text>
          </Box>
        </DialogAlert>
      </Grid>
    </>
  )
}

export default LoginPage
