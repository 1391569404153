export const capitalizedCase = (str: string | number,  doNotChangeCase?:boolean) => {
  if (typeof str !== 'string') return str;
  if(doNotChangeCase){
    return str
  }
 
  const words = str?.split(' ')
  const capitalizedWords = words?.map((word) => {
    if (word?.length > 0) {
      return word?.charAt(0)?.toUpperCase() + word?.slice(1)?.toLowerCase()
    } else {
      return ''
    }
  })
  const capitalizedStr = capitalizedWords?.join(' ')
  return capitalizedStr
}
