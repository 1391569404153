import React from 'react'
import { useNavigate } from 'react-router-dom'
import { useCookies } from 'react-cookie'

type ProtectedRoutesTypes = {
  children: React.ReactNode
}

function ProtectedRoutes({ children }: ProtectedRoutesTypes) {
  const navigate = useNavigate()
  const [cookies] = useCookies(['id_token'])
  const token = cookies.id_token
  React.useEffect(() => {
    if (!token) {
      navigate('/login')
    }
  }, [token, navigate])

  if (!token) return null
  return <>{children}</>
}

export default ProtectedRoutes
