import { useQuery } from 'react-query'
import { fetchWrapper } from 'src/utils/fetchWrapper'
import type { FromInventoryItem } from 'src/types/manageWorkOrder'

export function useFromInventoryItems(itemId: number) {
  const API_ENDPOINT = `/workorders/${itemId}/items`
  return useQuery('fromInventoryItems', async () => {
    return await fetchWrapper<FromInventoryItem[]>({
      method: 'GET',
      url: API_ENDPOINT,
    })
  })
}
