import React from 'react'
import { Card, Grid, SvgIcon } from '@mui/material'
import { UpdateButtonDialog } from 'src/components/ManageInventory'
import { OutlinedButton, Text } from 'src/components/common'
import { CardSubContainer, CardSubContent } from '../common/Card'
import { useDialog } from 'src/hooks/common'
import { UpdateIcon } from '../common/svg/UpdateIcon'
import { useTranslation } from 'react-i18next'
import useGoogleTranslation from 'src/services/useGoogleTranslation'
import type { ManageInventoryDetails } from 'src/types/manageInventory'

type WorkFlowSummaryDetailsCardProps = {
  inventoryDetails: ManageInventoryDetails
  opacity: number
  onUpdateClick: () => void
}

export default function ManageInventoryDetailsCard({
  inventoryDetails,
  opacity,
  onUpdateClick,
}: WorkFlowSummaryDetailsCardProps) {
  const catalogColor = useGoogleTranslation(inventoryDetails.CatalogColor)
  const { t } = useTranslation()
  const status = inventoryDetails.ReceivedStatus
  function getBackgroundColor(tags: string) {
    switch (tags) {
      case 'PARTIAL':
        return '#FFEC19'
      case 'YES':
        return '#3FF500'
      case 'NO':
        return '#FFA69E'
      default:
        return '#FFA69E'
    }
  }
  const backgroundColor = getBackgroundColor(status)

  const {
    isOpen: isUpdateButtonDialogOpen,
    closeDialog: closeUpdateButtonDialog,
    openDialog: OpenUpdateButtonDialog,
  } = useDialog()

  const handleopenDialog = () => {
    OpenUpdateButtonDialog()
    onUpdateClick()
  }
  return (
    <CardSubContainer opacity={opacity}>
      <Grid
        container
        padding={2}
      >
        <Grid
          container
          item
          rowGap={3}
          xs={8}
        >
          <CardSubContent
            title={t('vendor')}
            value={inventoryDetails.VendorName ?? '- -'}
          />
          <CardSubContent
            title={t('carton_number')}
            value={inventoryDetails.CartonNumber ?? '- -'}
            valueUpperCase={true}
            width='25%'
          />
          <CardSubContent
            title={t('garment_color')}
            value={catalogColor ?? '- -'}
            width='25%'
          />
          <CardSubContent
            title={t('quantity_shipped')}
            value={inventoryDetails.QuantityShipped ?? '- -'}
          />
          <CardSubContent
            title={t('brand')}
            value={inventoryDetails.BrandName ?? '- -'}
          />
          <CardSubContent
            title={t('catalog_style')}
            value={inventoryDetails.CatalogStyle ?? '- -'}
            valueUpperCase={true}
            width='25%'
          />
          <CardSubContent
            title={t('catalog_size')}
            value={inventoryDetails.CatalogSize ?? '- -'}
            valueUpperCase={true}
            width='25%'
          />
          <CardSubContent
            title={t('quantity_received')}
            value={inventoryDetails.QuantityReceived ?? '- -'}
          />
        </Grid>
        <Grid
          container
          item
          xs={4}
          gap={4}
          justifyContent='center'
          alignItems='center'
        >
          <Card
            sx={{
              backgroundColor: backgroundColor,
              borderRadius: '30%',
              width: '9vh',
              height: '9vh',
              margin: '10px',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <Text
              fontStyle='normal normal 600 70px/70px Poppins'
              upperCase={true}
              color='#000'
            >
              {(() => {
                switch (inventoryDetails.ReceivedStatus) {
                  case 'YES' || 'yes':
                    return 'Y'
                  case 'NO' || 'no':
                    return 'N'
                  case 'PARTIAL' || 'partial':
                    return 'P'
                  default:
                    return '-'
                }
              })()}
            </Text>
          </Card>
          <OutlinedButton
            onClick={handleopenDialog}
            sx={{
              height: '60px',
              borderRadius: '8px',
              padding: 3,
              '& .MuiButton-startIcon': { marginRight: 1 },
            }}
            startIcon={
              <SvgIcon
                sx={{ color: '#FFF', height: 30 }}
                viewBox='0 0 26 30 '
              >
                <UpdateIcon />
              </SvgIcon>
            }
            buttonProps={{}}
            textProps={{
              fontStyle: 'normal normal bold 18px/14px Poppins',
              typographyProps: { padding: 0 },
            }}
          >
            {t('Update')}
          </OutlinedButton>
          <UpdateButtonDialog
            isOpen={isUpdateButtonDialogOpen}
            closeDialog={closeUpdateButtonDialog}
            inventoryDetails={inventoryDetails}
          />
        </Grid>
      </Grid>
    </CardSubContainer>
  )
}
