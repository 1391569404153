import React from 'react'
import { Grid } from '@mui/material'
import { InfoSection } from '../../common'
import { formatDate } from 'src/utils/formatDate'
import { useTranslation } from 'react-i18next'
import type { WorkOrderItem } from 'src/types/manageWorkOrder'

type WorkOrdrDetailsProps = {
  workOrderItem: WorkOrderItem[]
}

const WorkOrderDetailsSection = ({ workOrderItem }: WorkOrdrDetailsProps) => {
  const { t } = useTranslation()
  const singleEntity = workOrderItem[0]
  const DueDate = formatDate(singleEntity.DueDate)
  const EstimatedDate = formatDate(singleEntity.EstimatedShippingDate)
  const InfoSectionsStyles = {
    titleFontStyle: 'normal normal 600 18px/22px Poppins',
    valueFontStyle: 'normal normal bold 22px/22px Poppins',
  }

  return (
    <Grid
      item
      container
      sx={{
        backgroundColor: 'var(--color-gray-light)',
        height: '100px',
        width: 1270,
        marginX: 'auto',
        paddingY: '9px',
        borderRadius: '15px',
        paddingX: '24px',
      }}
    >
      <Grid
        container
        item
        flex={1.2}
      >
        <InfoSection
          title={t('WORK ORDER #:')}
          value={singleEntity.WorkOrderId ?? '- -'}
          {...InfoSectionsStyles}
        />
        <InfoSection
          title={t('CUSTOMER PO #:')}
          value={singleEntity.CustomerPO ?? '- -'}
          {...InfoSectionsStyles}
        />
      </Grid>
      <Grid
        container
        item
        flex={1.5}
      >
        <InfoSection
          title={t('DUE DATE:')}
          value={DueDate ?? '- -'}
          {...InfoSectionsStyles}
        />
        <InfoSection
          title={t('ESTIMATED SHIPPING DATE:')}
          value={EstimatedDate ?? '- -'}
          {...InfoSectionsStyles}
        />
      </Grid>
      <Grid
        container
        item
        flex={1.5}
        direction='column'
      >
        <InfoSection
          title={t('Customer Name:')}
          value={singleEntity.CustomerName ?? '- -'}
          size={6}
          {...InfoSectionsStyles}
        />
        <InfoSection
          title={t('JOB Name:')}
          value={singleEntity.Jobname ?? '- -'}
          size={6}
          {...InfoSectionsStyles}
        />
      </Grid>
    </Grid>
  )
}

export default WorkOrderDetailsSection
