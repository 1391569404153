import { useMutation, useQueryClient } from 'react-query'
import { fetchWrapper } from 'src/utils/fetchWrapper'
import { toast } from 'react-toastify'
import { useTranslation } from 'react-i18next'
import type { WorkDone } from 'src/types/manageWorkOrder/WorkDoneTypes'

export function useAddWorkDone(workOrderId: number) {
  const queryClient = useQueryClient()
  const { t } = useTranslation()

  const createWorkDone = async (inventoryId: number, data: WorkDone) => {
    try {
      const response = await fetchWrapper({
        method: 'POST',
        url: `/inventory/${inventoryId}/${workOrderId}`,
        body: data,
      })
      toast.success(t('Work Order Updated successfully'))
      return response
    } catch (error) {
      console.error('API request failed:', error)
      toast.error(t('Failed to Update work Order'))
      throw error
    }
  }

  const mutateWorkDone = async (
    requests: { inventoryId: number; data: WorkDone }[]
  ) => {
    const promises = requests.map(({ inventoryId, data }) =>
      createWorkDone(inventoryId, data)
    )
    return await Promise.all(promises)
  }

  return useMutation('workDone', mutateWorkDone, {
    onSuccess: () => {
      queryClient.invalidateQueries('workDone')
    },
  })
}
