import React from 'react'
import { useNavigate } from 'react-router-dom'
import { Grid, SvgIcon } from '@mui/material'
import {
  Backarrow,
  FlagIcon,
  CheckBoxIcon,
  DocumentIcon,
} from 'src/components/common/svg'
import { FilledButton, OutlinedButton } from 'src/components/common'
import { useTranslation } from 'react-i18next'
import type { WorkOrderItem } from 'src/types/manageWorkOrder'

type WordOrderButtonsProps = {
  openAddNotesDialog: (initialFlagChecked: boolean) => void
  orderDetails: WorkOrderItem
}

export default function WordOrderButtons({
  openAddNotesDialog,
  orderDetails,
}: WordOrderButtonsProps) {
  const { t } = useTranslation()
  const navigate = useNavigate()

  return (
    <Grid
      item
      container
      alignItems='center'
    >
      <Grid
        item
        container
        xs={5}
      >
        <OutlinedButton
          onClick={() => {
            navigate('/work-order-summary')
          }}
          sx={{
            padding: 3,
            height: '60px',
            margin: 0,
            borderRadius: '8px',
            '& .MuiButton-startIcon': { marginRight: 1 },
          }}
          textProps={{
            fontStyle: 'normal normal 600 22px/22px Poppins',
            upperCase: true,
            opacity: 0.5,
          }}
          startIcon={<Backarrow />}
        >
          {t('Work order Summary')}
        </OutlinedButton>
      </Grid>
      <Grid
        item
        container
        xs={7}
        gap={2}
        justifyContent='flex-end'
      >
        <OutlinedButton
          onClick={() => {
            openAddNotesDialog(true)
          }}
          sx={{
            width: '170px',
            borderColor: '#FF0000',
            height: '60px',
            margin: 0,
            borderRadius: '8px',
            '& .MuiButton-startIcon': { marginRight: 1 },
          }}
          textProps={{
            fontStyle: 'normal normal bold 18px/18px Poppins',
            upperCase: true,
            color: 'var(--color-red)',
          }}
          startIcon={
            <SvgIcon sx={{ color: '#FF0000' }}>
              <FlagIcon />
            </SvgIcon>
          }
        >
          {t('Flag')}
        </OutlinedButton>
        <OutlinedButton
          onClick={() => {
            openAddNotesDialog(false)
          }}
          sx={{
            borderColor: '#AED432',
            padding: '2',
            height: '60px',
            margin: 0,
            borderRadius: '8px',
            '& .MuiButton-startIcon': { marginRight: 1 },
          }}
          textProps={{
            fontStyle: 'normal normal bold 18px/18px Poppins',
            upperCase: true,
          }}
          startIcon={
            <SvgIcon sx={{ color: '#AED432', width: 25, height: 25 }}>
              <DocumentIcon />
            </SvgIcon>
          }
        >
          {t('Add Note')}
        </OutlinedButton>
        <FilledButton
          onClick={() =>
            navigate(`/manage-pipeline-tasks`, { state: { orderDetails } })
          }
          sx={{
            borderColor: '#FF0000',
            borderRadius: '8px',
            marginRight: '3px',
            padding: '2',
            height: '60px',
            margin: 0,
            '& .MuiButton-startIcon': { marginRight: 1 },
          }}
          textProps={{
            fontStyle: 'normal normal bold 18px/18px Poppins',
            typographyProps: { sx: { padding: 0 } },
            upperCase: true,
          }}
          startIcon={
            <SvgIcon
              sx={{
                height: '60px',
                margin: 0,
                color: '#FFF',
                width: 30,
              }}
              viewBox='0 -5 50 50'
            >
              <CheckBoxIcon />
            </SvgIcon>
          }
        >
          {t('Work Done')}
        </FilledButton>
      </Grid>
    </Grid>
  )
}
