import React from 'react'
import { Grid, Card } from '@mui/material'
import WorkOrderDetailsSection from './WorkOrderDetailsSection'
import ProgressBar from 'src/components/WorkOrderLiveView/LiveViewProgressBar'
import useGoogleTranslation from 'src/services/useGoogleTranslation'
import type { WorkOrderItem } from 'src/types/manageWorkOrder'

type WorkOrdrDetailsProps = {
  workOrderItem: WorkOrderItem[]
}

export default function WorkOrderDetail({
  workOrderItem,
}: WorkOrdrDetailsProps) {
  const singleEntity = workOrderItem[0]
  const StageName = useGoogleTranslation(singleEntity.StageName)
  let statusTag
  switch (singleEntity.Flagged == null ? 0 : singleEntity.Flagged) {
    case 1:
      statusTag = 'flagged'
      break
    case 0:
      statusTag = 'inProduction'
      break
    default:
      statusTag = 'inQueue'
  }
  return (
    <Grid
      item
      container
      alignItems='center'
    >
      <Card
        sx={{
          backgroundColor: 'var(--color-dark-gray)',
          padding: '24px',
          borderRadius: '15px',
          boxShadow: '0px 4px 8px #00000026',
        }}
      >
        <Grid
          container
          rowGap={2}
          alignItems='center'
          justifyContent='center'
        >
          <ProgressBar
            statusTag={statusTag ?? 'inQueue'}
            totalTasks={singleEntity.TotalStages ?? '- -'}
            currentTask={singleEntity.CurrentStageOrder ?? '- -'}
            currentTaskTag={StageName ?? '- -'}
          />
          <WorkOrderDetailsSection workOrderItem={workOrderItem} />
        </Grid>
      </Card>
    </Grid>
  )
}
