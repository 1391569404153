import React from 'react'
import { Typography } from '@mui/material'
import type { TypographyProps } from '@mui/material'

export type TextProps = {
  children: React.ReactNode
  fontStyle?: string
  upperCase?: boolean
  color?: string
  align?: TypographyProps['textAlign']
  opacity?: number
  noWrap?: boolean
  typographyProps?: Omit<TypographyProps, 'children'>
}

function Text({
  children,
  fontStyle,
  upperCase,
  color,
  align,
  opacity = 1,
  noWrap = true,
  typographyProps,
}: TextProps) {
  const fontTextStyle = fontStyle || 'normal normal normal 18px/12px Poppins'

  const { sx, ...rest } = typographyProps || {}

  return (
    <Typography
      textAlign={align || 'center'}
      textTransform={upperCase ? 'uppercase' : undefined}
      sx={{ font: `${fontTextStyle}`, opacity: opacity, ...sx }}
      padding={typographyProps?.padding || 1}
      color={color || 'var(--color-white)'}
      noWrap={noWrap}
      {...rest}
    >
      {children}
    </Typography>
  )
}

export default Text
