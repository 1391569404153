import { Grid } from '@mui/material'
import { Text } from 'src/components/common'

const LiveViewTableHeader = () => {
  return (
    <Grid
      item
      container
      sx={{
        margin: '15px 30px 0 16px',
        padding: '11px 0',
        borderBottom: '1px solid rgba(255,255,255,0.5)',
      }}
    >
      <Grid
        item
        sx={{ width: '12%' }}
      >
        <Text
          fontStyle='normal normal  600 24px/14px Poppins'
          opacity={0.5}
          upperCase={true}
          align='left'
        >
          Work Order ID
        </Text>
      </Grid>
      <Grid
        item
        sx={{ width: '11%' }}
      >
        <Text
          fontStyle='normal normal 600 24px/14px Poppins'
          opacity={0.5}
          upperCase={true}
          align='left'
        >
          job name
        </Text>
      </Grid>
      <Grid
        item
        sx={{ width: '14%' }}
      >
        <Text
          fontStyle='normal normal 600 24px/14px Poppins'
          opacity={0.5}
          upperCase={true}
          align='left'
        >
          customer name
        </Text>
      </Grid>
      <Grid
        item
        sx={{ width: '10%' }}
      >
        <Text
          fontStyle='normal normal 600 24px/14px Poppins'
          opacity={0.5}
          upperCase={true}
          align='left'
        >
          Quantity
        </Text>
      </Grid>
      <Grid
        item
        sx={{ width: '13%' }}
      >
        <Text
          fontStyle='normal normal 600 24px/14px Poppins'
          opacity={0.5}
          upperCase={true}
          align='left'
        >
          Ship Date
        </Text>
      </Grid>
      <Grid
        item
        sx={{ width: '40%' }}
      >
        <Text
          fontStyle='normal normal 600 24px/14px Poppins'
          opacity={0.5}
          upperCase={true}
          align='center'
        >
          pipeline
        </Text>
      </Grid>
    </Grid>
  )
}

export default LiveViewTableHeader
