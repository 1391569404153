
export default function Backarrow() {
	return (
		<svg version="1.2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 34 40" width="34" height="40">
			<title>Screenshot</title>
			<defs>
				<image width="32" height="34" id="img1" href="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACAAAAAiCAMAAAAJbCvNAAAAAXNSR0IB2cksfwAAAGZQTFRFNUBLN0JKcIg/j684gp87RFJIO0dKkbE4rtQyosY0SFdHlbY3m702rNIyV2pEXXFCbIM/PktJgJw7YHVCg587fZg8f5o8h6Q6YXVCgZ07bINAXXFDjq04krE3VWdElLQ3o8Y0kK846ZJiKQAAACJ0Uk5TAAAA//8AAP///wD///8AAA8A/wD/////AP8AAP//AP///6V8NHAAAACOSURBVHiczdTZDoIwEIXhqSwekbqAimyi7/+ShgRDRsqZO+Pc/l/SpGlHRI3bRHEi65NuAewy3omYOrA3OmD13Oj+YPTjf3ecEjXn776YwvEOlHKhHVfxHBQ2uFlHuIqCu3BRO9GiCVyUEr4NvZVfic4SaT+LhyWCz16JMJjFsAI+4km+d8/7uEBetV4gb47zFAcvSPuaAAAAAElFTkSuQmCC" />
			</defs>
			<style>
			</style>
			<use id="Background" href="#img1" x="1" y="3" />
		</svg>
	)
}
