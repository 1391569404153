import React from 'react'
import {
  WorkOrderSummaryContainer,
  WorkOrderSummaryDetailsCard,
} from 'src/components/WorkOrderSummary'
import { useActiveWorkOrders } from 'src/hooks/WorkOrderSummary'
import { HelmetComponent, Text } from 'src/components/common'
import { ErrorMessage, LoadingSpinner } from 'src/components/common'
import type { WorkOrderSummaryDetails } from 'src/types/workOrderSummary'

type ActiveWorkOrdersResult = {
  data: WorkOrderSummaryDetails[]
  error: string | null
  isLoading: boolean
}

function WorkOrderSummary() {
  const [filtered, setFiltered] = React.useState<WorkOrderSummaryDetails[]>([])
  const {
    data: workOrderSummary,
    error,
    isLoading,
  } = useActiveWorkOrders() as ActiveWorkOrdersResult
  const sortedWorkOrderSummary = React.useMemo(() => {
    return workOrderSummary
      ?.filter((order) => order.OnHold !== "Yes")
      .slice()
      .sort((a, b) => b.WorkOrderID - a.WorkOrderID)
  }, [workOrderSummary])

  return (
    <>
      <HelmetComponent
        title='Work Order Summary | Silva Production'
        description='description'
        content='Work Order Summary | Silva Production'
      />
      <WorkOrderSummaryContainer
        setFiltered={setFiltered}
        dataset={sortedWorkOrderSummary}
      >
        {isLoading ? (
          <LoadingSpinner height={'70vh'} />
        ) : error ? (
          <ErrorMessage message={`${error}`} />
        ) : filtered?.length === 0 ? (
          <Text>No Matching Record(s) Found.</Text>
        ) : (
          <>
            {filtered?.map((details, index) => (
              <WorkOrderSummaryDetailsCard
                key={index}
                orderDetails={details}
                opacity={index % 2 === 0 ? 0.15 : 0.05}
              />
            ))}
          </>
        )}
      </WorkOrderSummaryContainer>
    </>
  )
}

export default WorkOrderSummary
