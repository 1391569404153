import React from 'react'
import { Grid } from '@mui/material'
import WorkOrderCarousel from './WorkOrderCarousel'
import WorkOrderTables from './WorkOrderTabs'
import type { WorkOrderItem } from 'src/types/manageWorkOrder'

type WorkOrderDashboardProps = {
  workOrderItem: WorkOrderItem[]
  workOrderId: number
  stopAutoPlay: boolean
  setStopAutoPlay: Function
}

function WordOrderDashboard({
  workOrderItem,
  workOrderId,
  stopAutoPlay,
  setStopAutoPlay,
}: WorkOrderDashboardProps) {
  const [selectedImageUrl, setSelectedImageUrl] = React.useState<string>('')

  const handleRowClick = (imageUrl: string) => {
    setSelectedImageUrl(imageUrl)
    setStopAutoPlay(true)
  }

  return (
    <Grid
      item
      container
      gap={2}
      flex={1}
      wrap='nowrap'
      justifyContent='space-between'
    >
      <WorkOrderCarousel
        workOrderId={workOrderId}
        selectedImageUrl={selectedImageUrl}
        stopAutoPlay={stopAutoPlay}
      />
      <WorkOrderTables
        workOrderItem={workOrderItem}
        workOrderId={workOrderId}
        onRowClick={handleRowClick}
        stopAutoPlay={stopAutoPlay}
        setStopAutoPlay={setStopAutoPlay}
      />
    </Grid>
  )
}

export default WordOrderDashboard
