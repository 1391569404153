import React, { useRef, useEffect } from 'react';
import { CardContent } from '@mui/material';

type LiveViewScrollableBodyContainerProps = {
  children: React.ReactNode;
  onRestart: () => void;
  isLoading: boolean;
};

function LiveViewScrollableBodyContainer({
  children,
  onRestart,
  isLoading,
}: LiveViewScrollableBodyContainerProps) {
  const scrollableContainerRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    const scrollableContainer = scrollableContainerRef.current;

    const scrollSlowly = async () => {
      if (scrollableContainer && !isLoading) {
        const pageHeight = scrollableContainer.clientHeight; // Height of each page
        const totalPages = Math.ceil(scrollableContainer.scrollHeight / pageHeight);
        let currentPage = 0;
        const pagePauseTime = 60*1000 // milliseconds
        
        while (currentPage < totalPages) {
          await smoothScroll(scrollableContainer, currentPage * pageHeight);
          currentPage++;
          await pause(pagePauseTime);
        }

        // All pages scrolled, restart if needed
        onRestart();
      }
    };

    const schrollDuration = 5*1000 // milliseconds
    const smoothScroll = (element: HTMLElement, to: number, duration = schrollDuration) => {
      return new Promise<void>((resolve) => {
        const start = element.scrollTop;
        const change = to - start;
        const increment = 20;
        let currentTime = 0;

        const animateScroll = () => {
          currentTime += increment;
          const val = easeInOutQuad(currentTime, start, change, duration);
          element.scrollTop = val;

          if (currentTime < duration) {
            requestAnimationFrame(animateScroll);
          } else {
            resolve();
          }
        };

        animateScroll();
      });
    };

    const pause = (ms: number) => {
      return new Promise<void>((resolve) => {
        setTimeout(resolve, ms);
      });
    };

    const easeInOutQuad = (
      t: number,
      b: number,
      c: number,
      d: number
    ) => {
      t /= d / 2;
      if (t < 1) return (c / 2) * t * t + b;
      t--;
      return (-c / 2) * (t * (t - 2) - 1) + b;
    };

    if (!isLoading) {
      scrollSlowly();
    }

    // Cleanup on component unmount
    return () => {};
  }, [isLoading, onRestart]);

  return (
    <CardContent
      ref={scrollableContainerRef}
      sx={{
        width: '100%',
        height: 'calc(100vh - 200px)',
        overflowY: 'scroll',
      }}
    >
      {children}
    </CardContent>
  );
}

export default LiveViewScrollableBodyContainer;