import { useMutation, useQueryClient } from 'react-query'
import { fetchWrapper } from 'src/utils/fetchWrapper'

export function useCreateWorkOrderActivity(workOrderID: number) {
  const API_ENDPOINT = `/workorders/${workOrderID}/activity/create`
  const queryClient = useQueryClient()

  const CreateWorkOrderActivity = async (data: any) => {
    return await fetchWrapper({
      method: 'POST',
      url: API_ENDPOINT,
      body: data,
    })
  }

  return useMutation('WorkOrderActivity', CreateWorkOrderActivity, {
    onSuccess: () => {
      queryClient.invalidateQueries('WorkOrderActivity')
    },
  })
}
