import React from 'react'
import {
  ManageInventoryContainer,
  ManageInventoryDetailsCard,
} from 'src/components/ManageInventory'
import { ErrorMessage, LoadingSpinner, Text } from 'src/components/common'
import { useActiveInventory } from 'src/hooks/ManageInventory'
import { HelmetComponent } from 'src/components/common'
import type { ManageInventoryDetails } from 'src/types/manageInventory'

type ActiveInventoryResult = {
  data: ManageInventoryDetails[]
  isLoading: boolean
  error: string | null
}

function ManageInventory() {
  const [filtered, setFiltered] = React.useState<ManageInventoryDetails[]>([])

  const handleUpdateClick = (inventoryDetails: ManageInventoryDetails) => {}
  const { data, isLoading, error } =
    useActiveInventory() as ActiveInventoryResult

  return (
    <>
      <HelmetComponent
        title='Manage Inventory | Silva Production'
        description='description'
        content='Manage Inventory | Silva Production'
      />
      <ManageInventoryContainer
        setFiltered={setFiltered}
        dataset={data}
      >
        {isLoading ? (
          <LoadingSpinner height={'70vh'} />
        ) : error ? (
          <ErrorMessage message={` ${error}`} />
        ) : filtered?.length <= 0 ? (
          <Text>No Matching Record(s) Found.</Text>
        ) : (
          filtered?.map((details, index) => (
            <ManageInventoryDetailsCard
              key={index}
              inventoryDetails={details}
              opacity={index % 2 === 0 ? 0.15 : 0.05}
              onUpdateClick={() => handleUpdateClick(details)}
            />
          ))
        )}
      </ManageInventoryContainer>
    </>
  )
}

export default ManageInventory
