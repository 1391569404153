export const formatDate = (inputDate: string | number) => {
  if (!inputDate || typeof inputDate !== 'string') {
    return '- -'
  }

  const formattedDate = new Date(inputDate)
    .toLocaleDateString('en-US', {
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
    })
    .replace(/\//g, '-')

  return formattedDate
}
