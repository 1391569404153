import React from "react";
import Carousel from "react-material-ui-carousel";
import { Card, Grid, SvgIcon, Box } from "@mui/material";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import { ImageIcon } from "../../common/svg";
import { LoadingSpinner, DialogAlert } from "src/components/common";
import { useDecorationItems } from "src/hooks/ManageWorkOrder";
import type { DecorationItems } from "src/types/manageWorkOrder";

type DecorationItemsResults = {
  data: DecorationItems[];
  isLoading: boolean;
  error: string | null;
};

type WorkOrderProps = {
  workOrderId: number;
  selectedImageUrl: string;
  stopAutoPlay: boolean;
};

const DefaultSVGImage = () => {
  return (
    <SvgIcon
      sx={{
        height: "400px",
        width: "400px",
        color: "#FFF",
        opacity: 0.15,
      }}
      viewBox="-1 0 20 20"
    >
      <ImageIcon />
    </SvgIcon>
  );
};

const WorkOrderCarousel = ({
  workOrderId,
  selectedImageUrl,
  stopAutoPlay,
}: WorkOrderProps) => {
  const { data, isLoading, error } = useDecorationItems(
    workOrderId
  ) as DecorationItemsResults;
  const [selectedImageIndex, setSelectedImageIndex] = React.useState<number>(0);
  const [open, setOpen] = React.useState<boolean>(false);
  const [img, setImg] = React.useState<string>("");

  React.useEffect(() => {
    if (data && data.length > 0) {
      const selectedIndex = data.findIndex(
        (item) => item.ArtDescriptionLink === selectedImageUrl
      );
      setSelectedImageIndex(selectedIndex >= 0 ? selectedIndex : 0);
    }
  }, [selectedImageUrl, data]);

  const isValidImageUrl = (url: string | null | undefined): boolean => {
    if (!url) {
      return false;
    }
    const imageExtensions = [".jpg", ".jpeg", ".png", ".gif", ".bmp", "webp", "jfif"];
    return imageExtensions.some((extension) =>
      url.toLowerCase().endsWith(extension)
    );
  };

  const handleExpand = (imgUrl: string) => {
    setImg(imgUrl);
    setOpen(true);
  };

  return (
    <Grid item sx={{ width: "40%" }}>
      <Card sx={{ height: "55.8vh" }}>
        <Carousel
          animation="slide"
          indicators={true}
          navButtonsAlwaysVisible
          cycleNavigation
          index={selectedImageIndex}
          autoPlay={!stopAutoPlay}
          NextIcon={
            <SvgIcon
              sx={{
                width: 50,
                height: 50,
              }}
            >
              <ArrowForwardIosIcon />
            </SvgIcon>
          }
          PrevIcon={
            <SvgIcon
              sx={{
                width: 50,
                height: 50,
              }}
            >
              <ArrowBackIosIcon />
            </SvgIcon>
          }
          navButtonsProps={{
            style: {
              width: 50,
              height: 50,
              stroke: "white",
              strokeWidth: "1px",
              WebkitTextStrokeWidth: "1px",
              backgroundColor: "transparent",
              left: 5,
              opacity: 0.5,
            },
          }}
          IndicatorIcon={null}
          indicatorIconButtonProps={{
            style: {
              backgroundColor: "#FFF",
              borderRadius: "8px",
              height: 10,
              margin: "0 8px",
              padding: 0,
              width: 50,
              opacity: 0.5,
              marginBottom: "40px",
            },
          }}
          activeIndicatorIconButtonProps={{
            style: {
              backgroundColor: "#FFF",
              opacity: 1,
            },
          }}
        >
          {isLoading ? (
            <LoadingSpinner height="37vh" width="20vh" />
          ) : error ? (
            [1, 2].map((item) => (
              <div
                key={item}
                style={{
                  backgroundColor: "inherit",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  height: "480px",
                }}
              >
                <DefaultSVGImage />
              </div>
            ))
          ) : (
            data.map((item) => (
              <div
                key={item.Id}
                style={{
                  backgroundColor: "inherit",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  height: "480px",
                  cursor: "pointer",
                }}
              >
                {isValidImageUrl(item.ArtDescriptionLink) ? (
                  <img
                    src={item.ArtDescriptionLink}
                    alt={`Item ${item.Id}`}
                    height={"400px"}
                    width={"400px"}
                    onError={(
                      e: React.SyntheticEvent<HTMLImageElement, Event>
                    ) =>
                      console.error(
                        `Error loading image: ${
                          (e.target as HTMLImageElement).src
                        }`
                      )
                    }
                    onClick={() => handleExpand(item.ArtDescriptionLink)}
                  />
                ) : (
                  <DefaultSVGImage />
                )}
              </div>
            ))
          )}
        </Carousel>
      </Card>

      <DialogAlert
        isOpen={open}
        handleClose={() => setOpen(false)}
        containerProps={{ maxWidth: "lg"}}
        showCloseIcon={false}
        style={{boxPadding: '0px'}}
      >
        <Box
          sx={{
            width: '100%',
            height: "500px",
            backgroundImage: `url("${img}")`,
            backgroundSize: 'cover',
            backgroundPosition: 'center',
            backgroundRepeat: 'no-repeat',
          }}
        />
      </DialogAlert>
    </Grid>
  );
};

export default WorkOrderCarousel;
