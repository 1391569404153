import React from 'react'
import { useFormikContext } from 'formik'
import { FilledButton } from '..'

type OutlinedFormButtonProps = {
  text: string
}

const FilledFormButton: React.FC<OutlinedFormButtonProps> = ({ text }) => {
  const { submitForm, isValid } = useFormikContext()
  return (
    <FilledButton
      sx={{
        margin: '20px 0 0 0',
        height: '60px',
        width: 250,
        padding: 0,
        borderRadius: 3,
        border: '3px solid var(--color-primary-green)',
        '&::focus': {
          border: '3px solid var(--color-primary-green)',
        },
        ...(isValid
          ? {}
          : {
              opacity: 0.5,
            }),
      }}
      textProps={{
        fontStyle: 'normal normal bold 18px/14px Poppins',
        upperCase: true,
      }}
      onClick={submitForm}
    >
      {text}
    </FilledButton>
  )
}

export default FilledFormButton
