import { Grid, GridProps, Typography } from '@mui/material'
import { capitalizedCase } from 'src/utils/capitalizedCase'

type InfoSectionsProps = {
  title: string
  value?: any
  size?: GridProps['xs']
  titleFontStyle?: string
  titleOpacity?: number
  direction?: 'row' | 'column'
  valueFontStyle?: string,
  valueStyle?: object,
  titleStyle?: object,
  upperCase?: boolean,
  doNotChangeCase?: boolean,
  rowGap?: number,
}
const InfoSection = ({
  title,
  value,
  titleFontStyle,
  titleOpacity,
  valueFontStyle,
  valueStyle,
  titleStyle,
  direction = 'row',
  size,
  doNotChangeCase=false,
  rowGap=1

}: InfoSectionsProps) => {
  const gridStyles: GridProps =
    direction === 'row'
      ? { alignItems: 'center', direction: 'row' }
      : { alignItems: 'flex-start', direction: 'column' }

  return (
    <Grid
      item
      container
      xs={size || 12}
      rowGap={rowGap}
      justifyContent='flex-start'
      {...gridStyles}
    >
      <Typography
        marginRight={1}
        textTransform='uppercase'
        sx={{ font: titleFontStyle, opacity: titleOpacity, ...titleStyle }}
      >
        {title.toLocaleUpperCase()}
      </Typography>
      <Typography
        color='var(--color-primary-green)'
        sx={{ font: valueFontStyle, ...valueStyle }}
        textAlign='left'
      >
        {capitalizedCase(value, doNotChangeCase)}
      </Typography>
    </Grid>
  )
}

export default InfoSection
