import React from 'react'
import { useFormikContext } from 'formik'
import { Grid } from '@mui/material'
import { FormTextField } from 'src/components/common/form'
import { WorkDoneFormValues } from '../Dialogs/WorkDoneDialog'

const FieldsPerID = ({ styleID }: { styleID: string }) => {
  const { values, setFieldValue } =
    useFormikContext<Record<string, WorkDoneFormValues>>()

  const { quantityDecorated, misprintsOrDefects, quantityShipped } =
    values[styleID]

  React.useEffect(() => {
    const calculatedValue =
      quantityDecorated - misprintsOrDefects - quantityShipped
    setFieldValue(`${styleID}.decoratedRemaining`, calculatedValue)
  }, [
    misprintsOrDefects,
    quantityDecorated,
    quantityShipped,
    setFieldValue,
    styleID,
    values,
  ])

  return (
    <Grid container gap={3} wrap='nowrap'>
      <Grid item container wrap='nowrap' width='35%' gap={1}>
        <FormTextField
          name={`${styleID}.styleID`}
          readOnly={true}
          width='40%'
        />
        <FormTextField name={`${styleID}.color`} readOnly={true} width='40%' />
        <FormTextField name={`${styleID}.size`} readOnly={true} width='20%' />
      </Grid>
      <Grid item container wrap='nowrap' width='50%' gap={1}>
        <FormTextField
          name={`${styleID}.quantityDecorated`}
          customNumberType={true}
          width='25%'
        />
        <FormTextField
          name={`${styleID}.decoratedWeight`}
          customNumberType={true}
          width='25%'
        />
        <FormTextField
          name={`${styleID}.misprintsOrDefects`}
          customNumberType={true}
          width='25%'
        />
        <FormTextField
          name={`${styleID}.quantityShipped`}
          customNumberType={true}
          width='25%'
        />
      </Grid>
      <Grid item container wrap='nowrap' width='10%'>
        <FormTextField
          name={`${styleID}.decoratedRemaining`}
          readOnly={true}
          type='number'
          width='100%'
        />
      </Grid>
    </Grid>
  )
}
export default FieldsPerID
