import React from 'react'
import { Grid, SvgIcon } from '@mui/material'
import { Text } from 'src/components/common'
import { capitalizedCase } from 'src/utils/capitalizedCase'

type CardSubContentProps = {
  title: string
  value: string | number
  icon?: React.ReactNode
  width?: string
  valueUpperCase?: boolean
}

const CardSubContent = ({
  title,
  value,
  width = '20%',
  valueUpperCase = false,
  icon = null,
}: CardSubContentProps) => {
  return (
    <Grid
      item
      width={width}
      padding={0}
      margin={0}
    >
      <Text
        fontStyle='normal normal 600 14px/14px Poppins'
        align='left'
        opacity={0.5}
        typographyProps={{ padding: 0, marginBottom: '2px' }}
        upperCase={true}
      >
        {title}
      </Text>
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
        }}
      >
        {icon && <SvgIcon fontSize='medium'>{icon}</SvgIcon>}
        <Text
          fontStyle='normal normal medium 18px/18px Poppins'
          align='left'
          typographyProps={{ padding: 0 }}
        >
          {typeof value === 'number'
            ? valueUpperCase
              ? value.toString().toUpperCase()
              : capitalizedCase(value.toString())
            : valueUpperCase
            ? (value as string).toUpperCase()
            : capitalizedCase(value as string)}
        </Text>
      </div>
    </Grid>
  )
}

export default CardSubContent
