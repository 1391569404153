import { Grid } from '@mui/material'
import React from 'react'

type LiveViewTableContainerProps = { children: React.ReactNode }

function LiveViewTableContainer({ children }: LiveViewTableContainerProps) {
  return (
    <Grid
      container
      sx={{
        padding: '11px',
      }}
    >
      {children}
    </Grid>
  )
}

export default LiveViewTableContainer
